import { Strings } from '../../Strings';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const BasicWarning = (props: { isOpen: boolean, dismiss: () => void, message: string}) => {

    return (<>
        <Dialog
            open={props.isOpen}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {Strings.headers.warning}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.dismiss()}>Okay</Button>
            </DialogActions>
        </Dialog>
    </>)
}
