import React, { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { setLoading, setTitle, setError } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { Strings } from '../../Strings'
import { API } from '../../Utils';
import { useQuery } from '../../Hooks';
import { RecordDto } from '../../generated/ClientApi';
import { Button, Stack, Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '80%',
    //height: '80%',
    borderRadius: '5px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    //boxShadow: 24,
    p: 2,
    overflow: 'hidden'
};

export const View = (): JSX.Element => {
    const dispatch = useStoreDispatch();
    const api = API.getClientApi();
    const query = useQuery();
    const user = useStoreSelector(state => state.app.user);
    const [record, setRecord] = useState<RecordDto>();
    const [publishedMetadata, setPublishedMetadata] = useState<string>("");
    const [statusPageText, setStatusPageText] = useState<string>("");
    const [showDoc, setShowDoc] = useState<boolean>(false);
    const [pdf, setPdf] = useState<string>("");
    const params = useParams();

    const loadPage = useCallback(async () => {
        await query(async () => {
            var record = await api.recordClient.findRecords(params.id);
            if (record != null && record.publishedVersionId != null) {
                if (record.recordStatusId !== 6 || (user?.roleId === 3 && record.recordStatusId === 6)) {
                    var publishedVersion = await api.commonClient.generateTitlePageHTML(record.publishedVersionId, false, false);
                    if (record.document?.ext === ".pdf" || record.document?.ext === ".docx" || record.document?.ext === ".doc") {
                        try {
                            await query(async () => {
                                var res = null;
                                res = await api.documentClient.downloadAttachmentFile(record?.publishedVersionId);
                                if (res.fileData !== "" && res.fileData !== null) {
                                    setPdf("data:" + res.contentType + ";base64," + res.fileData);
                                    setShowDoc(true);
                                }
                            });
                        } catch (e) {
                            setStatusPageText(Strings.errors.documentNotFound);
                        }
                    }
                    setRecord(record);
                    setPublishedMetadata(publishedVersion);

                } else {
                    setStatusPageText(Strings.warnings.obsolete)
                }
            } else {
                setStatusPageText(Strings.errors.documentNotFound);
            }
        })
    }, [api.commonClient, api.documentClient, api.recordClient, params.id, query, user]);

    const dl = async (id: string | undefined) => {
        dispatch(setLoading(true));
        try {
            await api.documentClient.downloadFile(id)
                .then((file) => {
                    const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute(
                        'download',
                        file.name != null ? file.name : file.documentId
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        dispatch(setTitle(Strings.pageTitles.view));
        loadPage();
    }, [dispatch]);



    //add error text
    return (<div>
        {record != null ?
            <Stack direction="column" spacing={6} sx={{ alignItems: 'center' }}>
                <Box></Box>
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {record?.name}
                    </Typography>
                    <Typography id="modal-modal-title" variant="caption" component="h2" >
                        {record?.recordPublicId}
                    </Typography>
                    <Stack direction="column" spacing={1} style={{ height: '100%' }}>
                        <Box sx={{ height: '100%' }}>
                            <Stack direction="row" spacing={1} >
                                {showDoc ?
                                    <>
                                        <div style={{ padding: '20px 10px 20px 20px', height: '600px', width: '800px' }}>
                                            <embed src={pdf} type="application/pdf" style={{ height: '100%', width: '100%' }} />
                                        </div>
                                        <div style={{ padding: '20px 20px 20px 10px', height: '600px', width: '800px', overflowY: 'scroll' }}>
                                            {parse(publishedMetadata)}
                                        </div>
                                    </>
                                    : <div style={{ padding: '20px', height: '600px', width: '800px', overflowY: 'scroll' }}>
                                        {parse(publishedMetadata)}
                                    </div>
                                }
                            </Stack>
                        </Box>
                            <Box>
                                <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button color='success' variant='contained' size='small' onClick={() => dl(record?.publishedVersionId)}>{Strings.button.download}</Button>
                                </Stack>
                            </Box>
                    </Stack>
                </Box>
            </Stack>
            : <>{statusPageText}</>
        }

    </div>
    );
}