import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '../../Hooks';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { RecordVersion, RecordVersionChangesDto } from '../../generated/ClientApi';
import { Box, Typography, Modal, Stack, Button, Grid } from '@mui/material';
import { ChangesSection } from './ChangesSection';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '400px',
    //height: '40%',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    overflow: 'hidden'
};

export const Summary = (props: { isOpen: boolean, onUpdate: (versionRecord: RecordVersionChangesDto[]) => void, dismiss: () => void, recordVersion: RecordVersion, recordType?: number, isInitialRecord: boolean, isObsolete: boolean }) => {
    const query = useQuery();
    const api = API.getClientApi();
    const [versionChanges, setVersionChanges] = useState<RecordVersionChangesDto[]>([]);
    const [errors, setErrors] = useState<number[]>([]);
    const [changeId, setChangeId] = useState<number>(0);

    const load = useCallback(async () => {
        if (props.recordVersion.versionStatusId !== 3 && props.recordVersion.versionStatusId !== 1 && props.recordVersion.versionStatusId !== 6) {
            await query(async () => {
                var changes = await api.recordClient.getRecordVersionChanges(props.recordVersion.versionId);
                if (changes != null) {
                    var i = 0;
                    changes.forEach((a) => { a.index = i; i++; });
                    setVersionChanges(changes);
                    setChangeId(i++);
                }
            });
        }
        else {
            addChange();
        }
    }, [query]);

    useEffect(() => {
        load();
    }, []);


    useEffect(() => {
    }, [errors]);

    const addChange = () => {
        setVersionChanges([...versionChanges].concat({ changeSectionNumber: "", changeSectionName: "", summaryChanges: "", index: changeId }));
        setChangeId(changeId + 1);
    };

    const removeChange = (index: number) => {
        if (versionChanges.length > 1) {
            var c = [...versionChanges].filter(a => a.index !== index);
            setVersionChanges(c);
        }
    };

    const updateChange = (updatedChange: RecordVersionChangesDto) => {
        const newChanges = [...versionChanges].map(a => {
            if (a.index === updatedChange.index) {
                return { ...a, changeSectionNumber: updatedChange.changeSectionNumber, changeSectionName: updatedChange.changeSectionName, summaryChanges: updatedChange.summaryChanges };
            }
            return a;
        });
        setVersionChanges(newChanges);
    };

    const finish = () => {
        var errorList: number[] = [];
        for (var a of [...versionChanges]) {
            if (a.changeSectionName === "" || a.changeSectionNumber === "" || a.summaryChanges === "") {
                errorList.push(a.index);
            }
        };

        if (errorList.length === 0) {
            props.onUpdate(versionChanges);
            props.dismiss();
        } else {
            setErrors([...errorList]);
        }
    };

    return (<>
        <Modal
            open={props.isOpen}
            //onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ padding: '0 0 20px 20px' }}>
                    What changes were made?
                </Typography>
                <Stack direction="column" spacing={1} >
                    <Grid container sx={{ maxHeight: '400px', maxWidth: '485px', overflowY: "scroll" }} spacing={0}>
                        {versionChanges != null ?
                            versionChanges.map((change, index) => (
                                <Grid item key={index}>
                                    <ChangesSection onChangeRemoved={removeChange} onUpdate={updateChange} index={index} changes={change} recordType={props.recordType!} isObsolete={props.isObsolete} errors={errors} />
                                </Grid>
                            ))
                            :
                            null
                        }
                    </Grid>
                    {props.isObsolete !== true/* && props.recordType !== 11*/ ?
                        <span style={{ float: 'right' }}>
                            <Button variant='contained' size='small' onClick={addChange}>Add Change</Button>
                        </span>
                        : null}
                </Stack>
                <Box>
                    <Stack direction="row" spacing={1} style={{ float: 'right' }}>
                        {errors.length > 0 ? <Typography id="modal-modal-title" variant="caption" sx={{ color: 'red' }}>
                            {Strings.errors.incompleteForm} &nbsp;&nbsp;&nbsp;
                        </Typography> : <></>}
                        <Button color="info" variant='contained' size='small' onClick={finish}>{Strings.button.submit}</Button>
                        <Button color="error" variant='contained' size='small' onClick={() => props.dismiss()}>{Strings.button.cancel}</Button>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    </>)
}