import { createSlice } from '@reduxjs/toolkit'
import { ApiException } from '../generated/ClientApi';
import { RootState } from './store';
import { Strings } from '../Strings'
import { ToastTypes } from '../Enums'


interface Toast {
    type: ToastTypes.INFO | ToastTypes.ERROR | ToastTypes.SUCCESS | ToastTypes.WARNING;
    text: string;
}

interface LayoutState {
    loading: boolean;
    error: string | undefined;
    title: string | undefined;
    toast: Toast | undefined;
}

const initialState: LayoutState = {
    loading: false,
    error: undefined,
    title: "",
    toast: undefined
};

const layoutSlice = createSlice({
    name: Strings.reduxSliceNames.layout,
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setToast: (state, action) => {
            state.toast = action.payload;
        },
        setError: (state, action) => {
            let message = Strings.errors.generic;
            if (action.payload instanceof ApiException) {
                const err = (action.payload as ApiException);
                message = err.response ? JSON.parse(err.response).message : err.message;
            } else if (action.payload instanceof Error) {
                message = (action.payload as Error).message;
            }
            state.toast = { text: message, type: ToastTypes.ERROR };
        },
    }
});
export const selectLayoutState = (state: RootState) => state.layout;
export const { setLoading, setTitle, setToast, setError } = layoutSlice.actions;
export default layoutSlice.reducer;