import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading, setError } from './redux/layoutSlice';

export const useQuery = () => {
    const dispatch = useDispatch();
    const execute = useCallback(async <T>(act: () => Promise<T>) => {
        dispatch(setLoading(true));
        try {
            return await act();
        } catch (err) {
            dispatch(setError(err));
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);
    return execute;
}