import { API } from '../../Utils';
import { Strings } from '../../Strings';
import { RecordDto } from '../../generated/ClientApi';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const DeleteDraft = (props: { isOpen: boolean, dismiss: () => void, record: RecordDto }) => {
    const api = API.getClientApi();

    const deleteDraft = async () => {
        await api.recordClient.deleteDraft(props.record.recordId);
        props.dismiss();
    }

    return (<>
        <Dialog
            open={props.isOpen}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {Strings.headers.warning}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete <b>{props.record == null ? "" : props.record.name}</b>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => deleteDraft()}>Delete</Button>
                <Button onClick={() => props.dismiss()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>)
}
