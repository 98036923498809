import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';
import {
    IAdminClient,
    AdminClient,
    ICommonClient,
    CommonClient,
    IAccountClient,
    AccountClient,
    IRecordClient,
    RecordClient,
    IDocumentClient,
    DocumentClient,
    IStakeholderClient,
    StakeholderClient
} from './generated/ClientApi';

export class ClientApiMapBase {
    private _signal: AbortSignal | undefined;
    public adminClient: IAdminClient;
    private userId: string | undefined;
    public commonClient: ICommonClient;
    public accountClient: IAccountClient;
    public recordClient: IRecordClient;
    public documentClient: IDocumentClient;
    public stakeholderClient: IStakeholderClient;
    private msalInstance?: PublicClientApplication;
    public setSignal(s: AbortSignal | undefined) {
        this._signal = s;
    }

    constructor(withAuth: boolean) {
        const baseUrl = this.getBaseUrl();
        const defaultFetch = window.fetch.bind(window);
        const useFetch = withAuth ? this.fetchProxy : defaultFetch;
        this.adminClient = new AdminClient(baseUrl, { fetch: useFetch });
        this.commonClient = new CommonClient(baseUrl, { fetch: useFetch });
        this.accountClient = new AccountClient(baseUrl, { fetch: useFetch });
        this.recordClient = new RecordClient(baseUrl, { fetch: useFetch });
        this.documentClient = new DocumentClient(baseUrl, { fetch: useFetch });
        this.stakeholderClient = new StakeholderClient(baseUrl, { fetch: useFetch });
        this.msalInstance = withAuth ? new PublicClientApplication(msalConfig) : undefined;
    }

    private fetchProxy = async (input: RequestInfo, init?: RequestInit) => {
        const token = await this.getAccessToken();
        //const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        init = init || {};
        init.headers = { ...init.headers, Authorization: `Bearer ${token}`, userId: this.userId ?? '' };
        init.signal = this._signal;
        return fetch(input, init);
    }

    private getAccessToken = async (): Promise<string> => {
        try {
            const accounts = this.msalInstance?.getAllAccounts();
            if (this.msalInstance && accounts && accounts.length > 0) {
                var silentResult = await this.msalInstance.acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    account: accounts[0]
                });
                return silentResult.accessToken;
            } else {
                throw new Error('login_required');
            }
        } catch (err) {
            if (this.isInteractionRequired(err as Error)) {
                await this.msalInstance?.acquireTokenRedirect({ scopes: loginRequest.scopes });
                return "";
            } else {
                throw err;
            }
        }
    }
    private isInteractionRequired = (error: Error): boolean => {
        if (!error.message || error.message.length <= 0) {
            return false;
        }

        return (
            error.message.indexOf('consent_required') > -1 ||
            error.message.indexOf('interaction_required') > -1 ||
            error.message.indexOf('login_required') > -1 ||
            error.message.indexOf('no_account_in_silent_request') > -1
        );
    }

    public getBaseUrl = () => window.location.origin;
    public getFetch = () => {
        const fetch = window.fetch.bind(window);
        return fetch;
    };
    public setUserId = (userId: string | undefined) => {
        this.userId = userId;
    }
}
