import React, { useEffect, useState } from 'react';
import { ToastTypes } from '../../Enums';
import { useQuery } from '../../Hooks';
import { BasicWarning } from '../../Popups/dialogs/BasicWarning';
import { EditModal } from '../../Popups/edit/Edit';
import { HistoryModal } from '../../Popups/history/History';
import { Preview } from '../../Popups/preview/Preview';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { FilterDto, RecordDto, UserDto } from '../../generated/ClientApi';
import { setError, setLoading, setToast } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { DeleteDraft } from '../../Popups/dialogs/DeleteDraft';

export const GridDetails = (props: { record: GridValidRowModel, updateGrid: () => void, filter: FilterDto }) => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const api = API.getClientApi();
    const user = useStoreSelector(state => state.app.user);
    const [modalIndex, setModalIndex] = useState<number>(0);
    const [warningIndex, setWarningIndex] = useState<number>(0);
    const [warningMsg, setWarningMsg] = useState<string>("");
    const [record, setRecord] = useState<RecordDto>();
    const [approver, setApprover] = useState<UserDto>();
    const [originator, setOriginator] = useState<string>();
    const navigate = useNavigate();

    const review = async (e: GridValidRowModel) => {
        var url = "/review/" + e.recordId;
        navigate(url);
    }

    const preview = async (e: GridValidRowModel) => {
        if (e.publishedVersionId != null) {
            await query(async () => {
                let res = await api.recordClient.getRecord(e.recordId);
                setRecord(res);
                setModalIndex(1);
            });
        } else {
            setWarningMsg(Strings.errors.publishedViewUnavailable);
            setWarningIndex(1);
        }
    }

    const edit = async (e: GridValidRowModel) => {
        let res = await api.recordClient.editAvailable(e.recordId);
        if (res === "") {
            await query(async () => {
                var res = await api.recordClient.getRecord(e.recordId);
                setRecord(res);
                setModalIndex(2);
            });
        } else {
            setWarningMsg(res);
            setWarningIndex(1);
        }
    }

    const history = async (e: GridValidRowModel) => {
        if (e.publishedVersionId != null) {
            await query(async () => {
                let res = await api.recordClient.getRecord(e.recordId);
                setRecord(res);
                setModalIndex(3);
            });
        } else {
            setWarningMsg(Strings.errors.publishedViewUnavailable);
            setWarningIndex(1);
        }
    }

    const download = async (e: GridValidRowModel) => {
        if (e.publishedVersionId != null) {
            dispatch(setLoading(true));
            try {
                await api.documentClient.downloadFile(e.publishedVersionId)
                    .then((file) => {
                        const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                        const link = document.createElement("a");
                        link.href = blobUrl;
                        link.setAttribute(
                            'download',
                            file.name != null ? file.name : file.documentId
                        );
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
            } catch (e) {
                dispatch(setError(e));
            } finally {
                dispatch(setLoading(false));
            }
        } else {
            setWarningMsg(Strings.errors.publishedDownloadUnavailable);
            setWarningIndex(1);
        }
    }

    const cancel = async (e: any) => {
        await query(async () => {
            await api.recordClient.cancelPendingSubmission(e.latestVersionId);
            props.updateGrid();
        });
    }

    const copyLink = (e: GridValidRowModel) => {
        if (e.publishedVersionId != null) {
            let link = window.location.origin + '/view/' + e.recordId
            navigator.clipboard.writeText(link);
            dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.linkCopied }))
        } else {
            setWarningMsg(Strings.errors.publishedCopyUnavailable);
            setWarningIndex(1);
        }
    }

    const deleteDraft = (e: any) => {
        if (e.publishedVersionId == null) {
            setRecord(e);
            setWarningIndex(2);
        }
    }

    const load = async () => {
        await query(async () => {
            setApprover((await api.recordClient.getApprovers(props.filter.isAllDocs ? props.record.publishedVersionId : (props.filter.isMyDocs && props.record.originatorId === user?.userId) || (props.filter.isMyDocs && props.record.isPendingReviewal) ? props.record.latestVersionId : props.filter.isAdmin ? props.record.latestVersionId : props.record.publishedVersionId)).at(0));
            setOriginator(props.filter.isAllDocs ? props.record.originator : props.filter.isAdmin ? (await api.accountClient.getUserById(props.record.originatorId)).displayName : (props.filter.isMyDocs && props.record.originatorId === user?.userId) || (props.filter.isMyDocs && props.record.isPendingReviewal) ? (await api.accountClient.getUserById(props.record.originatorId)).displayName : props.record.originator);
        });
    }

    useEffect(() => {
        load();
    }, [])

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box', position: 'sticky', left: 0 }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Grid container spacing={1} sx={{ p: 2 }}>
                    <Grid item xs={6}>
                        <Stack direction="column">
                            <Typography variant="body1">Title: {props.record.name}</Typography>
                            <Typography variant="body1">Document ID: {props.record.recordPublicId}</Typography>
                            <Typography variant="body1">Project: {props.record.projectNumber}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={5}>
                        <Stack direction="column">
                            <Typography variant="body1">Type: {props.record.recordTypeName}</Typography>
                            <Typography variant="body1">Approver: {approver?.displayName}</Typography>
                            <Typography variant="body1">Originator: {originator}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={5}>
                        <Stack direction="column">
                            <Typography variant="body1">Modified Date: {new Date(props.record.created).toLocaleDateString()}</Typography>
                            <Typography variant="body1">Issue Date: {props.record.approved == null ? "" : new Date(props.record.approved).toLocaleDateString()}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
                {props.filter.isMyDocs && props.record.comment != null && (props.record.recordStatusId === 5 || props.record.recordStatusId === 1) ?
                    <Grid container spacing={1} sx={{ p: 2 }}>
                        <Grid item xs={16}>
                            <Stack direction="column">
                                <Typography variant="body1"><b>Draft rejected reason: </b>{props.record.comment}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    :
                    <></>
                }
                <Grid container spacing={1} sx={{ p: 2 }}>
                    {props.record.isPendingReviewal || (props.filter.isAdmin && (props.record.recordStatusId === 2 || props.record.recordStatusId === 8)) ? <><Button variant="contained" color="warning" size="small" onClick={() => review(props.record)}>Review</Button>&nbsp;</> : null}
                    {(props.record.recordStatusId === 2 || props.record.recordStatusId === 8) && ((props.filter.isMyDocs && !props.record.isPendingReviewal) || (props.record.recordStatusId === 2 && props.filter.isAdmin)) ? <><Button variant="contained" color="secondary" size="small" onClick={() => cancel(props.record)}>Cancel Submission</Button>&nbsp;</> : null}
                    {props.filter.isAdmin && props.record.publishedVersionId == null ? <><Button variant="contained" color="secondary" size="small" onClick={() => deleteDraft(props.record)}>Delete Draft</Button>&nbsp;</> : null}
                    <Button variant="contained" color="primary" size="small" onClick={() => preview(props.record)}>Preview</Button>&nbsp;
                    {(user?.roleId !== 1 && !props.filter.isMyDocs) || (props.filter.isMyDocs && !props.record.isPendingReviewal) ? <><Button variant="contained" color="primary" size="small" onClick={() => edit(props.record)}>Edit</Button>&nbsp;</> : <></>}
                    <Button variant="contained" color="primary" size="small" onClick={() => download(props.record)}>Download</Button>&nbsp;
                    <Button variant="contained" color="primary" size="small" onClick={() => copyLink(props.record)}>Copy Link</Button>&nbsp;
                    {props.filter.isAdmin ? <><Button variant="contained" color="primary" size="small" onClick={() => history(props.record)}>Version History</Button>&nbsp;</> : null}
                </Grid>
                {modalIndex === 1 ?
                    <Preview isOpen={modalIndex === 1} dismiss={() => { setModalIndex(0); }} record={record!} />
                    : <></>}
                {modalIndex === 2 ?
                    <EditModal isOpen={modalIndex === 2} dismiss={() => { setModalIndex(0); props.updateGrid(); }} record={record!} isReviewEdit={null} />
                    : <></>}
                {modalIndex === 3 ?
                    <HistoryModal isOpen={modalIndex === 3} dismiss={() => { setModalIndex(0); }} record={record!} />
                    : <></>}
                {warningIndex === 1 ?
                    <BasicWarning isOpen={warningIndex === 1} dismiss={() => { setWarningIndex(0); }} message={warningMsg} />
                    : <></>}
                {warningIndex === 2 ?
                    <DeleteDraft isOpen={warningIndex === 2} dismiss={() => { setWarningIndex(0); props.updateGrid(); }} record={record!} />
                    : <></>}
            </Paper>
        </Stack>
    )
}