import { useStoreSelector } from '../../redux/store';
import { Typography } from '@mui/material';

export const NavTitle = () => {
    const title = useStoreSelector(state => state.layout.title);
    return (<>
        
            <Typography
                gutterBottom
                component="h2"
                variant="h3"
                color="inherit"
                align="center"
                noWrap
                sx={{ flex: 1, fontWeight: 400, fontSize: '50px' }}
            >
                {title}
            </Typography>
        
    </>)
}