import React, { useCallback, useEffect, useState } from 'react';
import { UserDto } from '../../generated/ClientApi';
import { useQuery } from '../../Hooks';
import { API } from '../../Utils';
import { Box, Typography, Autocomplete, TextField, Stack, AutocompleteRenderInputParams } from '@mui/material';

export const UserAutocomplete = (props: { initialUser: UserDto | null, selectUser: (users: UserDto) => void, label: string }) => {
    const api = API.getClientApi();
    const query = useQuery();
    const [users, setUsers] = useState<UserDto[]>([]);
    const [searchValue, setSearchValue] = useState("");

    const searchUser = useCallback(async (s: string) => {
        if (s) {
            await query(async () => {
                var res = await api.accountClient.findUser(s);
                if (res !== null) {
                    setUsers(res);
                }
            });
        } else {
            setUsers([]);
        }
    }, [api.accountClient, query])

    const onUserSelected = async (e: any, users: UserDto | null) => {
        if (users !== null) props.selectUser(users);
    }

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={props.label}
            />
        )
    }

    useEffect(() => {
        if (searchValue !== "") {
            const timeOutId = setTimeout(() => searchUser(searchValue), 750);
            return () => clearTimeout(timeOutId);
        }
    }, [searchValue, searchUser])

    return (<>
        <Autocomplete
            multiple={false}
            options={users}
            defaultValue={props.initialUser}
            clearIcon={false}
            limitTags={3}
            filterOptions={(x) => x}
            renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                    <Box
                        key={option.userId}
                        component="li"
                        {...optionProps}
                    >
                        <Stack direction="column">{option.displayName!}<Typography variant="subtitle1" sx={{ fontSize: '10px' }}>{option.jobTitle!}</Typography></Stack>
                    </Box>
                );
            }}
            getOptionLabel={option => option.displayName!}
            noOptionsText={"No results"}
            ListboxProps={{ style: { maxHeight: 150, overflow: 'auto' } }}
            sx={{ flexGrow: 1, padding: '20px 0 0 0' }}
            onInputChange={(e, value) => { setSearchValue(value) }}
            onChange={(e, value) => onUserSelected(e, value)}
            renderInput={(params) => renderInput(params)}
        />

    </>)
}
