import React, { useCallback, useEffect, useState } from 'react';
import { UserDto } from '../../generated/ClientApi';
import { useQuery } from '../../Hooks';
import { API } from '../../Utils';
import { Box, Typography, Autocomplete, TextField, Stack } from '@mui/material';

export const MultiUserAutocomplete = (props: { users: UserDto[], selectUser: (e: any, users: UserDto[]) => void, label: string }) => {
    const api = API.getClientApi();
    const query = useQuery();
    const [users, setUsers] = useState<UserDto[]>([]);
    const [findUsers, setFindUsers] = useState<UserDto[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [notFound, setNotFound] = useState<string[] | null>(null);

    const searchUser = useCallback(async (s: string) => {
        if (s) {
            await query(async () => {
                var res = await api.accountClient.findUser(s);
                if (res !== null) {
                    setFindUsers(res);
                }
            });
        } else {
            setFindUsers([]);
        }
    }, [api.accountClient, query])

    const onChangeDelimited = async (e: any, value: string) => {
        setNotFound(null);
        if (e != null) e.preventDefault();
        var res = await api.accountClient.getDelimitedUsers(value);
        if (res.users!?.length > 0) {
            let u = users.concat(res.users!);
            setUsers(u)
            props.selectUser(e, u);
        }
        if (res.usersNotFound!?.length > 0) {
            setNotFound(res.usersNotFound!);
        }
    }

    const onUserSelected = async (e: any, users: UserDto[] | null) => {
        if (users !== null) props.selectUser(e, users);
    }

    useEffect(() => {
        if (searchValue !== "") {
            const timeOutId = setTimeout(() => searchUser(searchValue), 750);
            return () => clearTimeout(timeOutId);
        }
    }, [searchValue, searchUser])

    return (<>
        <Stack direction="column" width="100%">
            <Autocomplete
                multiple
                options={findUsers}
                clearIcon={false}
                limitTags={3}
                renderOption={(props, option) => {
                    const { ...optionProps } = props;
                    return (
                        <Box
                            key={option.userId}
                            component="li"
                            {...optionProps}
                        >
                            <Stack direction="column">{option.displayName!}<Typography variant="subtitle1" sx={{ fontSize: '10px' }}>{option.jobTitle!}</Typography></Stack>
                        </Box>
                    );
                }}
                getOptionLabel={option => option.displayName!}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                value={props.users}
                ListboxProps={{ style: { maxHeight: 150, overflow: 'auto' } }}
                sx={{ flexGrow: 1 }}
                onInputChange={(e, value) => { setSearchValue(value); }}
                onChange={(e, value) => onUserSelected(e, value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            if (e.key === "Enter" && (e.target as HTMLInputElement).value) {
                                let text = (e.target as HTMLInputElement).value;
                                if (text.includes(";")) {
                                    onChangeDelimited(e, text);
                                }
                            }
                        }}
                    />
                )}
            />
            {notFound ? < Typography variant="caption" sx={{ color: 'red' }}>Users not found: {notFound?.map(a => a + "; ")}</Typography> : null}
        </Stack>
    </>)
}
