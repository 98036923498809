import React, { useCallback, useEffect, useState } from 'react';
import { setError, setLoading, setTitle, setToast } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { Strings } from '../../Strings'
import { ToastTypes } from '../../Enums';
import { API } from '../../Utils';
import { useQuery } from '../../Hooks';
import { Role, StakeholdersSection, UserDto } from '../../generated/ClientApi';
import { MultiUserAutocomplete } from '../../components/user/MultiUserAutocomplete';
import { UserAutocomplete } from '../../components/user/UserAutocomplete';
import { Button, Stack, Typography, Autocomplete, TextField, CircularProgress, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Admin = (): JSX.Element => {
    const dispatch = useStoreDispatch();
    const user = useStoreSelector(state => state.app.user);
    const api = API.getClientApi();
    const query = useQuery();
    const [selectedGroup, setSelectedGroup] = useState<UserDto[] | null>(null);
    const [selectedGroupId, setSelectedGroupId] = useState<number>();
    const [updateUser, setUpdateUser] = useState<UserDto | null>(null);
    const [updateUserRole, setUpdateUserRole] = useState<Role | null>();
    const [roles, setRoles] = useState<Role[] | null>(null);
    const [loading, setLoading_] = useState<boolean>(true);
    const [newStakeholderGroup, setNewStakeholderGroup] = useState<string>();
    const [removeGroupId, setRemoveGroupId] = useState<number | null>(null);
    const [types, setTypes] = useState<StakeholdersSection[] | undefined>();

    const loadPage = useCallback(async () => {
        setLoading_(true);
        var roles = await api.accountClient.getRoles();
        var types = await api.stakeholderClient.getStakeholderTypes();
        setRoles(roles);
        setTypes(types);
        setLoading_(false);
    }, [api.accountClient]);

    const groupSelect = async (e: any, value: any) => {
        setLoading_(true);
        setSelectedGroup(null);
        setSelectedGroupId(value.stakeholderSectionId);
        setSelectedGroup(await api.stakeholderClient.getStakeholdersTemplate(value.stakeholderSectionId));
        setLoading_(false);
    }

    const onStakeholderUpdate = async (e: any, value: UserDto[]) => {
        if (e != null) e.preventDefault();
        setSelectedGroup(value);
    }

    const onStakeholderSave = async () => {
        await query(async () => {
            var res = await api.stakeholderClient.saveStakeholdersTemplate(selectedGroup!, selectedGroupId)
            if (res > 0) {
                dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.submitUpdated }))
            } else {
                dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
            }
        })
    }

    const onNewStakeholderGroupSave = async () => {
        if (newStakeholderGroup !== undefined && newStakeholderGroup !== "" && newStakeholderGroup !== null) {
            await query(async () => {
                var res = await api.stakeholderClient.newStakeholderGroup(newStakeholderGroup);
                if (res > 0) {
                    dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.submitUpdated }))
                    setNewStakeholderGroup("");
                    loadPage();
                } else {
                    dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
                }
            })
        } else {
            dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
        }
    }

    const removeGroupSelect = async (e: any, value: any) => {
        setRemoveGroupId(value ? value.stakeholderSectionId : null);
    }

    const onRemoveStakeholderGroupSave = async () => {
        await query(async () => {
            var res = await api.stakeholderClient.removeStakeholderGroup(removeGroupId)
            if (res > 0) {
                dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.submitUpdated }))
                setRemoveGroupId(null);
                loadPage();
            } else {
                dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
            }
        })
    }

    const onUserSelect = async (user: UserDto) => {
        setUpdateUser(null);
        setUpdateUserRole(null);
        setUpdateUser(user);
        setUpdateUserRole(roles!.filter(a => a.roleId === user.roleId)[0]);
    }

    const onUserRoleSelect = async (e: any, value: any) => {
        if (value !== null) {
            setUpdateUserRole(value)
        }
    }

    const onUserUpdateSave = async () => {
        await query(async () => {
            var res = await api.accountClient.updateUserRole(updateUser?.userId, updateUserRole?.roleId);
            if (res > 0) {
                dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.submitUpdated }))
            } else {
                dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
            }
        })
    }

    const exportAllDocs = async () => {
        try {
            dispatch(setLoading(true));
            await api.recordClient.generateAllDocReport()
                .then((file) => {
                    const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute(
                        'download',
                        file.name != null ? file.name : file.documentId
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(setLoading(false));
        }
    }

    const openReportLibary = async () => {
        var url = await api.documentClient.getReportLibaryURL();
        window.open(url, "_blank")
    }

    useEffect(() => {
        dispatch(setTitle("Admin"));
        loadPage();
    }, [dispatch, loadPage]);

    return (<div>
        {user?.roleId === 3 ?
            <Stack direction="row">
                <Stack direction="column" width='33%' sx={{ marginTop: '10px', padding: '5px' }}>
                    <Typography
                        gutterBottom
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        sx={{ color: '#808080' }}
                    >
                        Edit Stakeholder Template Group Memebers
                        <Tooltip arrow title={<div>Add multiple users with a semicolon ( ; ) delimited list of full names or emails</div>} placement="bottom" enterDelay={500} leaveDelay={200}>
                            <InfoOutlinedIcon fontSize="small" sx={{ transform: "scale(0.75)" }} />
                        </Tooltip>
                    </Typography>
                    {types ?
                        <Autocomplete
                            style={{ padding: '10px 0px 10px 0px' }}
                            options={types!}
                            getOptionLabel={option => option.name!}
                            onChange={(e, value) => groupSelect(e, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Stakeholder Section"}
                                />
                            )}
                        /> : <></>
                    }
                    {selectedGroup ?
                        <>
                            <MultiUserAutocomplete users={selectedGroup} selectUser={onStakeholderUpdate} label={selectedGroup.length === 0 ? Strings.labels.addStakeholders : Strings.labels.stakeholders} />
                            <Button onClick={onStakeholderSave}>{Strings.button.save}</Button>
                        </>
                        : <></>
                    }
                    &nbsp;
                    <Typography
                        gutterBottom
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        sx={{ color: '#808080' }}
                    >
                        Add Stakeholder Group
                    </Typography>
                    <TextField
                        id="standard-basic"
                        fullWidth
                        disabled={user?.roleId! !== 3}
                        label={Strings.labels.name}
                        variant="outlined"
                        value={newStakeholderGroup}
                        margin="normal"
                        onChange={(e) => { setNewStakeholderGroup(e.target.value) }}
                    />
                    <Button onClick={onNewStakeholderGroupSave}>{Strings.button.save}</Button>
                    &nbsp;
                    <Typography
                        gutterBottom
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        sx={{ color: '#808080' }}
                    >
                        Remove Stakeholder Group
                    </Typography>
                    {types ?
                        <Autocomplete
                            style={{ padding: '10px 0px 10px 0px' }}
                            options={types!}
                            getOptionLabel={option => option.name!}
                            onChange={(e, value) => removeGroupSelect(e, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Stakeholder Section"}
                                />
                            )}
                        /> : <></>
                    }
                    <Button onClick={onRemoveStakeholderGroupSave}>{Strings.button.save}</Button>
                    {loading ?
                        <CircularProgress />
                        : <></>
                    }
                </Stack>

                <Stack direction="column" width='33%' sx={{ marginTop: '10px', padding: '5px' }}>
                    <Typography
                        gutterBottom
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        sx={{ color: '#808080' }}
                    >
                        Edit User Permissions
                    </Typography>
                    <div style={{ padding: '0px 0px 10px 0px' }}>
                        <UserAutocomplete initialUser={null} selectUser={onUserSelect} label={Strings.labels.name} />
                    </div>
                    {roles && updateUserRole ?
                        <>
                            <Autocomplete
                                style={{ padding: '10px 0px 10px 0px' }}
                                options={roles!}
                                defaultValue={updateUserRole}
                                getOptionLabel={option => option.name!}
                                onChange={(e, value) => onUserRoleSelect(e, value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Role"}
                                    />
                                )}
                            />
                            <Button onClick={onUserUpdateSave}>{Strings.button.save}</Button>
                        </>
                        :
                        <></>
                    }
                    {loading ?
                        <CircularProgress />
                        : <></>
                    }
                </Stack>

                <Stack direction="column" width='33%' sx={{ marginTop: '10px', padding: '5px' }}>
                    <Typography
                        gutterBottom
                        variant="body1"
                        color="inherit"
                        align="left"
                        noWrap
                        sx={{ color: '#808080' }}
                    >
                        Additional Controls
                    </Typography>
                    <div style={{ padding: '10px 0px 10px 0px' }}>
                        <Stack direction="column">
                            <Button variant="contained" color="primary" size='small' style={{ marginBottom: '5px' }} onClick={exportAllDocs}>Generate All Documents Report</Button>
                            <Button variant="contained" color="primary" size='small' style={{ marginBottom: '5px' }} onClick={openReportLibary}>View Previous Reports</Button>
                        </Stack>
                    </div>
                </Stack>
            </Stack>
            : <>{loading ? <CircularProgress /> : <>{Strings.errors.accessDenied}</>}</>}
    </div>);
}