import { useStoreSelector } from '../../redux/store';
import { Box, /*IconButton,*/ Typography } from '@mui/material';
//import { MenuButton } from './MenuButton';
//import { Strings } from '../../Strings';
//import LogoutIcon from '@mui/icons-material/Logout';

export const NavTopBar = () => {
    const user = useStoreSelector(state => state.app.user);
    //const report = [{ name: "Report a bug", link: Strings.links.devOpsBug }, { name: "Suggest feature", link: Strings.links.devOpsFeature }];

    return (<>
        <Box sx={{ height: 35, flex: 1 }}>
            <Box style={{ float: 'right' }}>
                {/*<MenuButton menuName="Report" menuItems={report} />*/}
                <Typography
                    gutterBottom
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    align="center"
                    noWrap
                    sx={{ flex: 1, fontSize: '14px' }}
                >
                    Hi, {user?.displayName}
                </Typography>
                &nbsp;&nbsp;
                {/*<IconButton><LogoutIcon /></IconButton>*/}
            </Box>
        </Box>
    </>)
}