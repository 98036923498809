export const Strings = {
    reduxSliceNames: {
        app: "app",
        layout: "layout"
    },
    thunks: {
        appInit: 'app/init'
    },
    errors: {
        generic: "Something went wrong",
        incompleteForm: "Missing required fields",
        commentReqired: "A comment is required when rejecting a submission.",
        publishedViewUnavailable: "Published record not available to view",
        publishedDownloadUnavailable: "Published record not available to download",
        publishedCopyUnavailable: "Published record not available to copy",
        accessDenied: "Access Denied",
        duplicateDocId: "Document Id already in use",
        approverOriginatorMatch: "Approver and originator cannot match",
        documentNotFound: "Document could not be found"
    },
    pageTitles: {
        home: "Document Center",
        myDocuments: "My Documents",
        review: "Submission Review",
        view: "Document Center"
    },
    headers: {
        allDocuments: "All Documents",
        myDocuments: "My Documents",
        adminView: "Admin View",
        newDocument: "New Document",
        stakeholders: "Stakeholders",
        warning: "Warning",
        cancel: "Cancel"
    },
    toasts: {
        submitSuccess: "Submitted",
        submitFailed: "Submission failed",
        createLibraryFailed: "Error creating edit library",
        linkCopied: "Copied to clipboard",
        submitUpdated: "Updated"
    },
    warnings: {
        newRecordCancel: "Changes will not be saved. If changes were made from the online template, save the document locally to your computer. Do you want to cancel this draft?",
        obsolete: "The document you are attempting to access has been obsoleted and is no longer available. If you have any questions, please contact the Document Center administrators"
    },
    button: {
        submitForApproval: "Submit for approval",
        submit: "Submit",
        draft: "Save as Draft",
        discardSave: "Discard Save",
        deleteDocument: "Delete Document",
        deleteDraft: "Delete Draft",
        cancel: "Cancel",
        attachment: "Attach file",
        new: "New",
        addSection: "Add Section",
        download: "Download",
        editFile: "Edit File",
        finish: "Finish",
        continue: "Continue",
        approve: "Approve",
        reject: "Reject",
        view: "View",
        fromTemplate: "Create from template",
        save: "Save",
        copyLink: "Copy link",
        export: "Export",
        makeChanges: "Make Changes",
        edit: "Edit",
        moreInfo: "More Info"
    },
    links: {
        documentSite: "https://mcdean365.sharepoint.com/sites/DocsTest",
        documentLibrary: "https://mcdean365.sharepoint.com/sites/DocsTest/DocumentRepository",
        devOpsBug: "https://dev.azure.com/mcdean365/MCD.DocumentCenter/_workitems/create/Bug?templateId=e6c930c5-ec26-4869-aa84-3c6b51804c5b&ownerId=b6f9284f-da48-4a5a-b302-b56fa81632b7",
        devOpsFeature: "https://dev.azure.com/mcdean365/MCD.DocumentCenter/_workitems/create/Task?templateId=ec751c42-60e5-4792-bfc3-8072eb3f16fc&ownerId=b6f9284f-da48-4a5a-b302-b56fa81632b7",
        devOpsChangeLog: "https://dev.azure.com/mcdean365/MCD.DocumentCenter/_wiki/wikis/MCD.DocumentCenter.wiki/196/Change-Log",
        controlDocument: "https://docs.mcdean.com/view/e5a5b508-1105-438d-9b6c-4dbcbedbdf84",
        divisionsHelp: "https://mcdean365.sharepoint.com/sites/DocumentCenterApp-DEV/Img/divisions_img.png"
    },
    labels: {
        name: "Name",
        documentId: "Document Id",
        documentContent: "Document Content",
        created: "Created",
        projectNumber: "Project Number",
        approver: "Approver",
        obsolete: "Obsolete",
        stakeholders: "Stakeholders",
        addStakeholders: "Add New Stakeholders",
        newStakeholderGroup: "Add New Stakeholder Group",
        originator: "Originator"
    }
};