import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const BasicConfirm = (props: { isOpen: boolean, dismiss: () => void, title: string, message: string, confirm: () => void }) => {

    return (<>
        <Dialog
            open={props.isOpen}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.dismiss()}>Close</Button>
                <Button color='error' onClick={() => props.confirm()}>Cancel Draft</Button>
            </DialogActions>
        </Dialog>
    </>)
}
