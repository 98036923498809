import ClientApiMap from "./ClientApiMap";


export const API = {
    getClientApi: () => {
        if (!(window as any)["clientApi"]) {
            (window as any)["clientApi"] = new ClientApiMap();
        }
        return (window as any)["clientApi"] as ClientApiMap;
    }
}