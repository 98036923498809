import React, { useEffect, useState, useCallback } from 'react';
import { useStoreDispatch } from '../../redux/store';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { useQuery } from '../../Hooks';
import { ToastTypes } from '../../Enums';
import parse from 'html-react-parser';
import { RecordDto } from '../../generated/ClientApi';
import { Box, Typography, Modal, Stack, Button } from '@mui/material';
import { setError, setToast } from '../../redux/layoutSlice';

export const Preview = (props: { isOpen: boolean, dismiss: () => void, record: RecordDto }) => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const api = API.getClientApi();
    const [metadata, setMetadata] = useState<string>("");
    const [pdf, setPdf] = useState<string>("");
    const [showDoc, setShowDoc] = useState<boolean>(false);

    const loadPage = async () => {
        await query(async () => {
            var record = await api.recordClient.getRecord(props.record.recordId);
            if (record.document?.ext === ".pdf" || record.document?.ext === ".docx" || record.document?.ext === ".doc") {
                viewDoc(record);
            }
            var result = await api.commonClient.generateTitlePageHTML(props.record.publishedVersionId, false, false);
            setMetadata(result);
        });
    };

    const viewDoc = async (record: RecordDto) => {
        try {
            await query(async () => {
                var res = null;
                res = await api.documentClient.downloadAttachmentFile(record?.publishedVersionId);
                if (res.fileData !== "" && res.fileData !== null) {
                    setPdf("data:" + res.contentType + ";base64," + res.fileData);
                    setShowDoc(true);
                }
            });
        } catch (e) {
            dispatch(setError(e));
        }
    }

    const download = useCallback(async () => {
        try {
            await api.documentClient.downloadFile(props.record.publishedVersionId)
                .then((file) => {
                    const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute(
                        'download',
                        file.name != null ? file.name : file.documentId
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (e) {
            dispatch(setError(e));
        }
    }, [api.documentClient, dispatch, props.record.publishedVersionId, query]);

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href + 'view/' + props.record.recordId);
        dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.linkCopied }))
    }

    useEffect(() => {
        loadPage();
    }, []);

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        //width: '80%',
        //height: '80%',
        borderRadius: '10px',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
        overflow: 'hidden'
    };

    const closeUpload = () => {
        props.dismiss();
    };

    return (<>
        <Modal
            open={props.isOpen}
            onClose={closeUpload}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2" >
                    {props.record?.name}
                </Typography>
                <Typography id="modal-modal-title" variant="caption" component="h2" >
                    {props.record?.recordPublicId}
                </Typography>
                <Stack direction="column" spacing={1} style={{ height: '100%' }}>
                    <Box sx={{ height: '100%' }}>
                        <Stack direction="row" spacing={1} >
                            {showDoc ?
                                <>
                                    <div style={{ padding: '20px 10px 20px 20px', height: '600px', width: '800px' }}>
                                        <embed src={pdf} type="application/pdf" style={{ height: '100%', width: '100%' }} />
                                    </div>
                                    <div style={{ padding: '20px 20px 20px 10px', height: '600px', width: '800px', overflowY: 'scroll' }}>
                                        {parse(metadata)}
                                    </div>
                                </>
                                : <div style={{ padding: '20px', height: '600px', width: '800px', overflowY: 'scroll' }}>
                                    {parse(metadata)}
                                </div>
                            }
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                <Button color='success' variant='contained' size='small' onClick={copyLink}>{Strings.button.copyLink}</Button>
                                &nbsp;
                                <Button color='success' variant='contained' size='small' onClick={download}>{Strings.button.download}</Button>
                            </span>
                            <span>
                                <Button color="error" variant='contained' size='small' onClick={closeUpload}>{Strings.button.cancel}</Button>
                            </span>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Modal>
    </>)
}

