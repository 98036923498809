import { configureStore } from '@reduxjs/toolkit'
import layoutReducer from './layoutSlice';
import appReducer from './appSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'




const store = configureStore({
    reducer: {
        layout: layoutReducer,
        app: appReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export const useStoreDispatch = () => useDispatch<typeof store.dispatch>();
export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;



