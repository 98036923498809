import React, { useCallback, useEffect, useState } from 'react';
import { useStoreSelector } from '../../redux/store';
import { API } from '../../Utils';
import { Strings } from '../../Strings';
import { StakeholdersSection, UserDto, StakeholdersDto } from '../../generated/ClientApi';
import { AddSectionButton } from './AddSectionButton';
import { StakeholderSection as Section } from './StakeholderSection';
import { Grid, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Stakeholders = (props: { onUpdate: (stakeholders: StakeholdersDto[]) => void, currentStakeholders?: StakeholdersDto[], versionId?: string }) => {
    const api = API.getClientApi();
    const [availableTypes, setAvailableTypes] = useState<StakeholdersSection[] | undefined>(useStoreSelector(state => state.app.stakeholderTypes));
    const [sections, setSections] = useState<StakeholdersDto[]>([]);

    const onStakeholderTypeSelected = (stakeholderType: StakeholdersSection) => {
        removeAvailableType(stakeholderType);
        setSections(sections?.concat({ type: stakeholderType, users: [] }))
    };

    const onUserChange = (type: StakeholdersSection, users: UserDto[]) => {
        var section;
        setSections(sections => {
            section = sections.map(section => {
                if (section.type?.stakeholderSectionId === type.stakeholderSectionId) {
                    return { type: section.type, users: users }
                }
                return section;
            })
            return section;
        });
        if (section != null) props.onUpdate(section);
    }

    const onSectionRemoved = (removed: StakeholdersSection) => {
        var s = sections.filter(a => a.type?.stakeholderSectionId !== removed.stakeholderSectionId)
        setSections(s);
        addAvailableType(removed);
        props.onUpdate(s);
    }

    const addAvailableType = (section: StakeholdersSection) => {
        setAvailableTypes(availableTypes?.concat(section).sort((a, b) => a.name!.toLowerCase() > b.name!.toLowerCase() ? 1 : -1))
    }

    const removeAvailableType = (section: StakeholdersSection) => {
        setAvailableTypes(availableTypes?.filter(a => a.stakeholderSectionId !== section.stakeholderSectionId).sort((a, b) => a.name!.toLowerCase() > b.name!.toLowerCase() ? 1 : -1));
    }

    const loadStakeholders = useCallback(async () => {
        if (props.currentStakeholders != null) {
            setSections(props.currentStakeholders!);
        }
    }, [props.currentStakeholders]);

    const loadAvailableTypes = async () => {
        if (props.versionId !== undefined) {
            var aT = await api.stakeholderClient.getAvailableStakeholderTypes(props.versionId!);
            setAvailableTypes(aT);
        }
    };

    useEffect(() => {
        loadStakeholders();
    }, [loadStakeholders]);

    useEffect(() => {
        loadAvailableTypes();
    }, []);

    return (<>
        <Typography
            gutterBottom
            variant="body1"
            color="inherit"
            align="left"
            noWrap
            sx={{ color: '#808080' }}
        >
            {Strings.headers.stakeholders}
            <Tooltip arrow title={<div>Add multiple users with a semicolon ( ; ) delimited list of full names or emails</div>} placement="bottom" enterDelay={500} leaveDelay={200}>
                <InfoOutlinedIcon fontSize="small" sx={{ transform: "scale(0.75)" }} />
            </Tooltip>
        </Typography>
        <Grid container sx={{ maxHeight: '500px', overflowY: "scroll" }} spacing={0}>
            {sections != null ?
                sections.map((section, index) => (
                    <Grid item key={index}>
                        <Section key={section.type?.stakeholderSectionId} stakeholderType={section.type!} users={section.users!} onUserChange={onUserChange} onSectionRemoved={onSectionRemoved} />
                    </Grid>
                ))
                : null}
        </Grid>
        <AddSectionButton stakeholderTypes={availableTypes} onSelected={onStakeholderTypeSelected} />
    </>)
}

