import React, { useState, useEffect } from 'react';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { useQuery } from '../../Hooks';
import { ToastTypes } from '../../Enums';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { Stakeholders } from '../../components/stakeholder/Stakeholders';
import { UserAutocomplete } from '../../components/user/UserAutocomplete';
import { DocumentHelp } from '../dialogs/DocumentHelp';
import { RecordDto, RecordType, StakeholdersDto as StakeholderSection, AddRecordDto, User, Document, DocumentTemplate, UserDto } from '../../generated/ClientApi';
import { Box, Typography, Modal, FormControl, TextField, Stack, Button, IconButton, Menu, MenuItem, InputLabel, Input } from '@mui/material';
import { setToast, setLoading } from '../../redux/layoutSlice';
import { Cancel } from '@mui/icons-material';
import { BasicConfirm } from '../../Popups/dialogs/BasicConfirm';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    overflow: 'hidden'
}

export const NewModal = (props: { isOpen: boolean, dismiss: () => void, type: RecordType, user: User }) => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const api = API.getClientApi();
    const user = useStoreSelector(state => state.app.user);
    const [record, setRecord] = useState<RecordDto | undefined>();
    const [stakeholders, setStakeholders] = useState<StakeholderSection[]>();
    const [approvers, setApprovers] = useState<UserDto | null>();
    const [originator, setOriginator] = useState<UserDto | null>();
    const [file, setFile] = useState<File | null>();
    const [error, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");
    const [editTemplate, setEditTemplate] = useState<Document | undefined>();
    const [documentTemplates, setDocumentTemplates] = useState<DocumentTemplate[]>();
    const [generatedDocumentId, setGeneratedDocumentId] = useState<string>("0001");
    const [publicIdPrefix, setPublicIdPrefix] = useState<string>("");
    const [documentIdHelpURL, setDocumentIdHelpURL] = useState<string>("");
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [popupIndex, setPopupIndex] = useState<number>(0);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onStakeholderUpdate = (stakeholders: StakeholderSection[]) => {
        setStakeholders(stakeholders);
    }

    const onApproverChange = (approver: UserDto) => {
        setApprovers(approver);
    }

    const onOriginatorChange = (originator: UserDto) => {
        setOriginator(originator);
    }

    const cancel = () => {
        if (editTemplate !== undefined) {
            api.documentClient.discardEdit(editTemplate?.documentId);
        }
        setPopupIndex(0);
        closeUpload();
    }

    const closeUpload = () => {
        props.dismiss();
    }

    const submit = async () => {
        var errorList = "";
        errorList += record?.name === "" || record?.name === undefined || record === undefined ? "Name; " : "";
        errorList += record?.recordPublicId === "" || record?.recordPublicId === undefined || record === undefined ? "Document Id; " : "";
        errorList += stakeholders?.length === 0 || stakeholders === undefined ? "Stakeholders; " : "";
        errorList += approvers === null || approvers === undefined ? "Approver; " : "";
        errorList += (file == null && editTemplate == null) ? "File; " : "";
        if (errorList !== "") {
            setErrorText("Missing required field: " + errorList);
            setError(true);
        }
        else if (await api.recordClient.hasDuplicatePublicId(record?.recordPublicId)) {
            setErrorText(Strings.errors.duplicateDocId);
            setError(true);
        }
        else if ((approvers !== null && originator !== null && approvers?.userId === originator?.userId) || (approvers !== null && (originator === undefined || originator === null) && approvers?.userId === user?.userId)) {
            setErrorText(Strings.errors.approverOriginatorMatch);
            setError(true);
        }
        else {
            setError(false);
            createNewRecord();
        }
    }

    const createNewRecord = async () => {
        if (file != null || editTemplate != null) {
            await query(async () => {
                var _record = record;
                _record!.recordTypeId = props.type!.recordTypeId;
                _record!.originator = originator!;
                var document;
                var documentId;
                if (editTemplate === undefined) {
                    document = { data: file!, fileName: file!.name };
                    documentId = await api.documentClient.uploadDocument(document, _record!.recordTypeId);
                } else {
                    documentId = editTemplate!.documentId;
                }
                var app = [];
                app.push(approvers!);
                var addRecord: AddRecordDto = { record: _record!, stakeholders: stakeholders!, documentId: documentId, approvers: app };
                var newRecord = await api.recordClient.addRecord(addRecord);
                if (newRecord != null) {
                    dispatch(setToast({ type: ToastTypes.SUCCESS, text: Strings.toasts.submitSuccess }))
                } else {
                    dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
                }
                closeUpload();
            })
        } else {
            alert("missing document");
        }
    }

    const openTemplate = async (templateId: string) => {
        await query(async () => {
            clearFile();
            var document = await api.documentClient.editNewTemplate(templateId);
            setEditTemplate(document);
            await window.open(await api.documentClient.getEditLink(document.documentId), '_blank');
        })
    }

    const fileChanged = async (files: FileList | null) => {
        if (files) {
            dispatch(setLoading(true));
            setFile(files[0]);
            dispatch(setLoading(false));
        }
    }

    const getAvailableDocumentId = async (value: any) => {
        var res = await api.recordClient.getAvailableDocumentId(value);
        setGeneratedDocumentId(res);
        setPublicIdPrefix((value).toUpperCase());
        var newId = (value).toUpperCase() + "-" + res;
        setRecord(record => ({ ...record!, recordPublicId: newId }))
    }

    const clearFile = () => {
        setFile(null);
    }

    const templateMenu = () => {
        return (<>
            <Button variant="outlined" color="primary" size='small' component="label" onClick={(e: any) => openMenu(e)}>Create From Template</Button>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {documentTemplates?.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => openTemplate(option.templateId)}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </>)
    };

    const load = async () => {
        const [template, url] = await Promise.all([
            api.recordClient.getDocumentTemplate(props.type?.recordTypeId),
            api.commonClient.getSharepointSiteURL()
        ]);
        setDocumentTemplates(template);
        setDocumentIdHelpURL(url + props.type?.guideUrl);
        setPublicIdPrefix(props.type?.defaultPublicRecordId!);
        setOriginator(props.user);
    };

    useEffect(() => {
        load();
    }, []);

    return (<>
        <Modal
            open={props.isOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ padding: '0 0 20px 20px' }}>
                    New {props.type?.parent?.name} - {props.type?.name}
                </Typography>
                <Stack direction="column" spacing={1} style={{ height: '100%' }}>
                    <Stack direction="row" spacing={1} >
                        <FormControl fullWidth>
                            <Box component='form' sx={{ padding: '20px', height: '550px', width: '450px'/*, overflowY: 'scroll'*/ }} noValidate autoComplete='off'>
                                <TextField
                                    required
                                    fullWidth
                                    id="standard-basic"
                                    variant="standard"
                                    label={Strings.labels.name}
                                    margin="normal"
                                    onChange={(e) => { setRecord(record => ({ ...record!, name: e.target.value })) }}
                                />
                                <Stack direction="row" spacing={1}>
                                    <FormControl fullWidth sx={{ width: '75%' }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-amount">Document Id *</InputLabel>
                                        <Input
                                            id="standard-basic"
                                            value={publicIdPrefix == null ? "" : publicIdPrefix}
                                            onChange={(e) => setPublicIdPrefix(e.target.value)}
                                            onBlur={(e) => getAvailableDocumentId(e.target.value)}
                                        />
                                    </FormControl>
                                    <Typography variant="body1" sx={{ fontSize: '18px', paddingTop: '20px' }}>
                                        - {generatedDocumentId}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Button variant="text" onClick={() => setPopupIndex(1)} sx={{ padding: '0px' }}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '10px', padding: '0px', color: 'blue' }}>
                                            <u>How to format Id</u>
                                        </Typography>
                                    </Button>
                                    <span style={{ width: '245px' }}></span>
                                    <Typography variant="subtitle1" sx={{ fontSize: '10px', padding: '0px' }}>
                                        Unique ID
                                    </Typography>
                                </Stack>
                                <TextField
                                    fullWidth
                                    id="standard-basic"
                                    variant="standard"
                                    label={Strings.labels.projectNumber}
                                    margin="normal"
                                    onChange={(e) => { setRecord(record => ({ ...record!, projectNumber: e.target.value })) }}
                                />
                                <UserAutocomplete initialUser={null} selectUser={onApproverChange} label={Strings.labels.approver} />
                                {user?.roleId === 3 ?
                                    <UserAutocomplete initialUser={props.user} selectUser={onOriginatorChange} label={Strings.labels.originator} />
                                    : <></>}
                            </Box>
                        </FormControl>
                        <Box component='form' sx={{ height: '550px', width: '420px' }} noValidate autoComplete='off'>
                            <Stakeholders onUpdate={onStakeholderUpdate} />
                        </Box>
                    </Stack>
                    {error ?
                        <span style={{ textAlign: 'center' }}>
                            <Typography id="modal-modal-title" variant="caption" sx={{ color: 'red' }}>
                                {errorText}
                            </Typography>
                        </span>
                        : <></>
                    }
                    <Box>
                        <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                <Button variant="outlined" color="primary" size='small' component="label">{Strings.button.attachment}<input type="file" hidden multiple={false} onChange={(e) => fileChanged(e.target.files)} onClick={(e) => e.currentTarget.value = ""} /></Button>
                                &nbsp;&nbsp;
                                {file != null ?
                                    <Typography variant="caption">
                                        <span>{file?.name}&nbsp;<IconButton sx={{ padding: '0px' }} onClick={() => clearFile()} ><Cancel color="error" fontSize="small" /></IconButton></span>
                                    </Typography>
                                    : templateMenu()
                                }

                            </span>
                            <span>
                                <Button color="info" variant='contained' size='small' onClick={submit}>{Strings.button.submit}</Button>
                                &nbsp;
                                <Button color="error" variant='contained' size='small' onClick={() => setPopupIndex(2)}>{Strings.button.cancel}</Button>
                            </span>
                        </Stack>
                    </Box>
                </Stack>
                {popupIndex === 1 ?
                    <DocumentHelp isOpen={popupIndex === 1} dismiss={() => { setPopupIndex(0); }} imgURL={documentIdHelpURL} />
                    : null}
                {popupIndex === 2 ?
                    <BasicConfirm isOpen={popupIndex === 2} dismiss={() => { setPopupIndex(0); }} title={Strings.headers.cancel} message={Strings.warnings.newRecordCancel} confirm={cancel} />
                    : null}
            </Box>
        </Modal>
    </>)
}