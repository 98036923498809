import React, { useState } from 'react';
import { MenuOptions } from '../../models/interfaces'
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const MenuButton = (props: { menuName: string, menuItems: MenuOptions[] }) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    return (<>
        <Button variant="text" size="small" style={{ color: '#808080' }} onClick={(e) => openMenu(e)}>{props.menuName}<KeyboardArrowDownIcon /></Button>
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            keepMounted
        >
            {props.menuItems.map((option, index) => (
                <MenuItem
                    key={index}
                    onClick={() => window.open(option.link, "_blank")}
                >
                    {option.name}
                </MenuItem>
            ))}
        </Menu>
    </>)
}

