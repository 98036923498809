import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RecordType, StakeholdersSection, UserDto } from '../generated/ClientApi';
import { RootState } from './store';
import { User } from '../generated/ClientApi';
import { API } from '../Utils';
import { Strings } from '../Strings'
import { setError, setLoading } from './layoutSlice';

interface AppState {
    user: User | undefined;
    users: UserDto[] | undefined;
    recordTypes: RecordType[] | undefined;
    stakeholderTypes: StakeholdersSection[] | undefined;
}

const initialState: AppState = {
    user: undefined,
    users: undefined,
    recordTypes: undefined,
    stakeholderTypes: undefined
};

export const getInitialInfo = createAsyncThunk(Strings.thunks.appInit, async (params, { dispatch }) => {
    const api = API.getClientApi();
    try {
        const result = await Promise.all([api.accountClient.getCurrent(), api.recordClient.getRecordTypes(), api.stakeholderClient.getStakeholderTypes()/*, api.accountClient.getUsers()*/]);
        return result;
    } catch (e) {
        dispatch(setError(e));
        return [undefined,undefined,undefined/*,undefined*/];
    } finally {
        dispatch(setLoading(false));
    }
});

const appSlice = createSlice({
    name: Strings.reduxSliceNames.app,
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getInitialInfo.fulfilled, (state, action) => {
                const user: User | undefined = action.payload[0];
                state.user = user;
                //state.users = action.payload[3];
                state.recordTypes = action.payload[1];
                state.stakeholderTypes = action.payload[2];
            })
    }
});

export const selectAppState = (state: RootState) => state.app;
export const { setUsers } = appSlice.actions;
export default appSlice.reducer;