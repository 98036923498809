import React from 'react';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { setToast } from '../../redux/layoutSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, { onClose: () => void, severity: "error" | "success" | "info" | "warning", children: any }>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" onClose={props.onClose} sx={{ width: '100%' }} color={props.severity}>{props.children}</MuiAlert>;
});

export const GlobalToast = () => {
    const toast = useStoreSelector(state => state.layout.toast);
    const dispatch = useStoreDispatch();
    const onHiding = () => {
        dispatch(setToast(undefined));
    }
    return (<>
        {toast && <Snackbar open={true} autoHideDuration={5000} onClose={onHiding}>
            <Alert onClose={onHiding} severity={toast.type} >
                {toast.text}
            </Alert>
        </Snackbar>}
    </>
    );
}