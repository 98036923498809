import { NavTopBar } from './NavTopBar'
import { NavMenu } from './NavMenu'

export const Navigation = () => {
    return (<>
        <NavTopBar/>
        <NavMenu />
        <br/>
    </>
    );
}