import React, { useEffect, useState, useCallback } from 'react';
import { RecordVersionChangesDto } from '../../generated/ClientApi';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, TextField, Stack, IconButton, Paper } from '@mui/material';

export const ChangesSection = (props: { onChangeRemoved: (index: number) => void, onUpdate: (changes: RecordVersionChangesDto) => void, index: number, changes: RecordVersionChangesDto, recordType: number, isObsolete: boolean, errors: number[] }) => {
    const [changes, setChanges] = useState<RecordVersionChangesDto>();
    const [numberError, setNumberError] = useState<boolean>(false);
    const [nameError, setNameError] = useState<boolean>(false);
    const [summaryError, setSummaryError] = useState<boolean>(false);

    const onChangeRemoved = () => {
        props.onChangeRemoved(props.changes.index);
    }

    const onUpdate = () => {
        props.onUpdate(changes!);
    }

    const load = useCallback(async () => {
        if (props.changes) {
            var c = props.changes;
            if (props.recordType === 11) {
                c.changeSectionName = "ALL";
                c.changeSectionNumber = "ALL";
            }
            if (props.isObsolete) {
                c.changeSectionName = "OBSOLETE";
                c.changeSectionNumber = "OBSOLETE";
                c.summaryChanges = "OBSOLETE";
            }
            if (props.errors.includes(props.changes.index)) {
                c.changeSectionNumber === "" ? setNumberError(true) : setNumberError(false);
                c.changeSectionName === "" ? setNameError(true) : setNameError(false);
                c.summaryChanges === "" ? setSummaryError(true) : setSummaryError(false);
            }

            setChanges(c);
        }
    }, [props.changes, props.errors, props.isObsolete, props.recordType]);

    useEffect(() => {
        load();
    }, [load]);

    return (<>
        <Box sx={{ height: '200px', width: '450px', marginTop: '10px' }}>
            <Paper variant="outlined" sx={{ padding: '8px' }}>
                <Stack direction="column">
                    <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" sx={{ padding: '0px' }}>
                            #{props.index + 1}
                        </Typography>
                        <IconButton sx={{ width: '2px', height: '2px' }} onClick={() => onChangeRemoved()} ><CloseIcon fontSize="small" /></IconButton>
                    </Stack>
                    {changes != null ?
                        <>
                            <Stack sx={{ paddingTop: '3px' }} direction="row">
                                <TextField sx={{ padding: '2px', width: '100%' }} error={numberError} disabled={props.isObsolete || props.recordType === 11} required id="standard-basic" size="small" label="Section #" value={changes.changeSectionNumber} onChange={(e) => { setChanges({ ...changes!, changeSectionNumber: e.target.value }) }} onBlur={onUpdate} />
                                <TextField sx={{ padding: '2px', width: '100%' }} error={nameError} disabled={props.isObsolete || props.recordType === 11} required id="standard-basic" size="small" label="Section Name" value={changes.changeSectionName} onChange={(e) => { setChanges({ ...changes!, changeSectionName: e.target.value }) }} onBlur={onUpdate} />
                            </Stack>
                            <Stack sx={{ paddingTop: '8px' }} direction="row">
                                <TextField sx={{ padding: '2px', width: '100%' }} error={summaryError} disabled={props.isObsolete} multiline minRows={3} maxRows={3} required id="standard-basic" size="small" label="Brief Summary of Change" value={changes.summaryChanges} onChange={(e) => { setChanges({ ...changes!, summaryChanges: e.target.value }) }} onBlur={onUpdate} />
                            </Stack>
                        </>
                        : null}
                </Stack>
            </Paper>
        </Box>
    </>)
}
