import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useStoreDispatch } from './redux/store';
import { Layout } from './Layout';
import { GlobalLoading } from './components/global/GlobalLoading';
import { GlobalToast } from './components/global/GlobalToast';
import { RouterConfig, userIsAllowed } from './RouterConfig';
import { getInitialInfo } from './redux/appSlice';
import { LicenseInfo } from '@mui/x-data-grid-pro';


const getBaseUrl = () => {
    if (document && document.getElementsByTagName('base').length > 0) {
        return document.getElementsByTagName('base')[0].getAttribute('href')?.toString();
    } else {
        return "";
    }
}
LicenseInfo.setLicenseKey('34e3aaba04b01139817023f2a682a3bcTz05NDcyMSxFPTE3NTMyOTQ3ODQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

export const App = (): JSX.Element => {
    const dispatch = useStoreDispatch();
    useEffect(() => {
        dispatch(getInitialInfo());
    }, [dispatch]);
    //TODO: implement!!!
    const userRoles = 1;


    return (<BrowserRouter basename={getBaseUrl()}>
        <Layout key="layout">
            <Routes>
                {RouterConfig.filter(a => userIsAllowed(a.roles, userRoles)).map(cfg => <Route key={cfg.route} path={cfg.route} element={cfg.render} />)}
            </Routes>
        </Layout>
        <GlobalToast key="global-toast" />
        <GlobalLoading key="global-loading" />
    </BrowserRouter>
    );
}
