import React, { useState } from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import { Strings } from '../../Strings';
import { StakeholdersSection } from '../../generated/ClientApi';

export const AddSectionButton = (props: { stakeholderTypes?: StakeholdersSection[], onSelected: (stakeholderType: StakeholdersSection) => void }) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onSelectType = (type: StakeholdersSection) => {
        if (type) {
            props.onSelected(type);
        };
        closeMenu();
    };

    return (<Box sx={{ width: '400px', marginTop: '10px' }}>
        <Button onClick={(e) => openMenu(e)}>{Strings.button.addSection}</Button>
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            PaperProps={{
                style: {
                    maxHeight: '150px',
                    width: '400px',
                }
            }}
            onClose={closeMenu}
            keepMounted
        >
            {props.stakeholderTypes && props.stakeholderTypes.length > 0 ?
                props.stakeholderTypes?.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => onSelectType(option)}
                    >
                        {option.name}
                    </MenuItem>

                ))
                : <MenuItem disabled={true}>None</MenuItem>
            }
        </Menu>
    </Box>)
}

