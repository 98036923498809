import { Clear, Delete, Grading } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { darken, lighten, styled } from '@mui/material/styles';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../Hooks';
import { DeleteDraft } from '../../Popups/dialogs/DeleteDraft';
import { API } from '../../Utils';
import { FilterDto, RecordDto, RecordGridDto } from '../../generated/ClientApi';
import { GridDetails } from './GridDetails';

const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

export const DocumentGrid = (props: { records: RecordGridDto[], updateGrid: () => void, filter: FilterDto, gridLoading: boolean }) => {
    const query = useQuery();
    const api = API.getClientApi();
    const [warningIndex, setWarningIndex] = useState<number>(0);
    const [record, setRecord] = useState<RecordDto>();
    const navigate = useNavigate();

    const updateGrid = async () => {
        props.updateGrid();
    }

    const review = async (e: any) => {
        var url = "/review/" + e.recordId;
        navigate(url);
    }

    const cancel = async (e: any) => {
        await query(async () => {
            await api.recordClient.cancelPendingSubmission(e.latestVersionId);
            updateGrid();
        });
    }

    const deleteDraft = (e: any) => {
        if (e.publishedVersionId == null) {
            setRecord(e);
            setWarningIndex(1);
        }
    }

    const actionColumn = (e: any) => {
        return (
            <span style={{ textAlign: 'right' }}>
                {(props.filter.isMyDocs && e.row.isPendingReviewal) || (props.filter.isAdmin && (e.row.recordStatusId === 2 || e.row.recordStatusId === 8)) ? <Tooltip title="Review"><IconButton sx={{ padding: 0, margin: 0 }} onClick={() => review(e.row)} ><Grading fontSize="small" sx={{ transform: "scale(0.85)", padding:0, margin: 0 }} /></IconButton></Tooltip> : null}
                {(e.row.recordStatusId === 2 || e.row.recordStatusId === 8)  && ((props.filter.isMyDocs && !e.row.isPendingReviewal) || (e.row.recordStatusId === 2 && props.filter.isAdmin)) ? <Tooltip title="Cancel Submission"><IconButton sx={{ padding: 0, margin: 0 }} onClick={() => cancel(e.row)} ><Clear fontSize="small" sx={{ transform: "scale(0.85)", padding: 0, margin: 0 }} /></IconButton></Tooltip> : null}
                {props.filter.isAdmin && e.row.publishedVersionId == null ? <Tooltip title="Delete Draft"><IconButton sx={{ padding: 0, margin: 0 }} onClick={() => deleteDraft(e.row)} ><Delete fontSize="small" sx={{ transform: "scale(0.85)", padding: 0, margin: 0 }} /></IconButton></Tooltip> : null}
            </span>
        );
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Title', flex: 3, renderCell: (a) => (<Tooltip title={a.row?.name} enterDelay={750}><span>{a.row?.name}</span></Tooltip>) },
        { field: 'recordPublicId', headerName: 'Document Id', flex: 2, renderCell: (a) => (<Tooltip title={a.row?.recordPublicId} enterDelay={750}><span>{a.row?.recordPublicId}</span></Tooltip>) },
        { field: 'projectNumber', headerName: 'Project', flex: 1 },
        { field: 'revision', headerName: 'Rev', maxWidth: 10, align: 'center' },
        { field: 'recordTypeName', headerName: 'Type', flex: 1, renderCell: (a) => (<Tooltip title={a.row?.recordTypeName} enterDelay={750}><span>{a.row?.recordTypeName}</span></Tooltip>) },
        { field: 'originator', headerName: 'Originator', flex: 1 },
        { field: 'approver', headerName: 'Approver', valueGetter: (a) => a.row?.recordStatusId === 3 ? a.row?.approvedBy : a.row?.latestApprover, flex: 1 },
        /*{ field: 'comment', headerName: 'Comment', flex: 1, renderCell: (a) => (a.row?.comment !== "" ? <Tooltip title={a.row?.comment} enterDelay={750}><span>{a.row?.comment}</span></Tooltip> : <></>) },*/
        { field: 'created', type: 'dateTime', headerName: 'Modified', valueFormatter: (a) => new Date(a?.value).toLocaleDateString(), width: 100 },
        { field: 'approved', type: 'dateTime', headerName: 'Issued', valueFormatter: (a) => new Date(a?.value).toLocaleDateString(), width: 100 },
        { field: 'recordStatusName', headerName: 'Status', width: 120, renderCell: (a) => (a.row.isPendingReviewal && props.filter.isMyDocs ? "Review Required" : a.row.recordStatusId === 8 && props.filter.isMyDocs ? "Pending" : a.row.recordStatusName) },
        { field: 'isPendingReviewal' },
        { field: 'action', headerName: '', width: 80, sortable: false, renderCell: (e) => actionColumn(e) }
    ];

    const getDetailPanelContent = useCallback((row) => {
        return <GridDetails record={row} updateGrid={updateGrid} filter={props.filter} />
    }, [updateGrid]);

    const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
        '& .super-app-theme--true': {
            backgroundColor: getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode,
                    ),
                },
            },
        }
    }));

    return (<>
        <StyledDataGrid
            sx={{ height: '750px' }}
            getRowId={(r) => r.recordId}
            rows={props.records}
            columns={columns}
            rowHeight={25}
            loading={props.gridLoading}
            getRowClassName={(e) => `super-app-theme--${props.filter.isMyDocs ? e.row.isPendingReviewal : false}`}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        recordStatusName: !props.filter.isAllDocs,
                        action: !props.filter.isAllDocs,
                        originator: props.filter.isAdmin,
                        approver: !props.filter.isAllDocs,
                        comment: props.filter.isMyDocs,
                        isPendingReviewal: false,
                        created: !props.filter.isAllDocs,
                        approved: props.filter.isAllDocs
                    },
                },
                sorting: {
                    sortModel: props.filter.isMyDocs ? [{ field: 'isPendingReviewal', sort: 'desc' }, { field: 'recordStatusName', sort: 'desc' },  { field: 'created', sort: 'desc' }] : props.filter.isAdmin ? [{ field: 'created', sort: 'desc' }] : [{ field: 'approved', sort: 'desc' }]
                },
            }}
            pagination
            getDetailPanelContent={({ row }) => getDetailPanelContent(row)}
            getDetailPanelHeight={() => 'auto'}
        />
        {warningIndex === 1 ?
            <DeleteDraft isOpen={warningIndex === 1} dismiss={() => { setWarningIndex(0); updateGrid(); }} record={record!} />
            : <></>}
    </>)
}

