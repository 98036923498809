import React, { ReactNode } from 'react';
import { Navigation } from './components/navigation/Navigation';
import Container from '@mui/material/Container';

export const Layout = ({ children }: { children: ReactNode }) => {
    return (<React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'top', alignItems: 'stretch', flex: 1, flexDirection: 'column', padding: '0 10px' }}>
            <Container maxWidth="xl">
                <Navigation />
                {children}
            </Container>
        </div>
    </React.Fragment>
    );
}