import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionType } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';



const rootElement = document.getElementById('root');
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        const accessTokenRequest = {
            scopes: ['User.Read'],
            account: accounts[0]
        };
        msalInstance.acquireTokenSilent(accessTokenRequest).then(() => { })
            .catch((err) => console.log(err));
    }
});

const authRequest = {
    ...loginRequest
};

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </MsalAuthenticationTemplate>
    </MsalProvider>,
    rootElement);
//ReactDOM.render(
//            <Provider store={store}>
//                <App />
//            </Provider>,
//    rootElement);

