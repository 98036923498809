import React, { useState } from 'react';
import { Strings } from '../../Strings'
import { Button, Menu, MenuItem } from '@mui/material';
import { Add } from '@mui/icons-material';
import { RecordType } from '../../generated/ClientApi';
import { useStoreSelector } from '../../redux/store';

export const NewButton = (props: { onSelected: (recordType: RecordType) => void }) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const recordTypes = useStoreSelector(state => state.app.recordTypes);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    const onSelectType = (type: RecordType) => {
        if (type) {
            props.onSelected(type);
        };
        closeMenu();
    };

    return (<>
        <Button variant="outlined" color="success" size="medium" onClick={(e) => openMenu(e)}><span style={{ marginTop: 2, marginRight: 4 }}>{Strings.button.new}</span><Add /></Button>
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={closeMenu}
            keepMounted
        >
            {recordTypes?.map((option, index) => (
                !option.hidden ?
                    <MenuItem
                        key={index}
                        onClick={() => onSelectType(option)}
                    >
                        {option.name}
                    </MenuItem>
                    : null
            ))}
        </Menu>
    </>)
}

