import React, { useEffect, useState } from 'react';
import { useStoreSelector } from '../../redux/store';
import { FilterDto } from '../../generated/ClientApi';
import { SearchBar } from './SearchBar';
import { Box, Typography, Paper, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup } from '@mui/material';

export const DocumentGridFilter = (props: { onFilterUpdate: (filter: FilterDto) => void, filter: FilterDto }) => {

    const user = useStoreSelector(state => state.app.user);
    const initial = {
        form: false,
        workinstruction: false,
        workprocedure: false,
        qualityplan: false,
        qualitymanual: false,
        nonqc: false,
        template: false,
        obsolete: false,
        //unworkprocedure: false,
        //unworkinstruction: false,
        //unstructured: false,
        expired: false
    };
    const [filterBy, setFilterBy] = React.useState(initial);
    const [filterTypes, setFilterTypes] = useState<number[]>([]);
    const [radioSelected, setRadioSelected] = useState<string>("All Documents");

    

    const onSearchUpdate = async (_searchTerm: string) => {
        var newFilter: FilterDto | undefined = {
            isAllDocs: props.filter.isAllDocs,
            isMyDocs: props.filter.isMyDocs,
            isAdmin: props.filter.isAdmin,
            recordTypeId: props.filter.recordTypeId,
            searchTerm: _searchTerm
        };
        props.onFilterUpdate(newFilter);
    }

    const onFilterByUpdate = async (e: any) => {
        setFilterBy({
            ...filterBy,
            [e.target.name]: e.target.checked,
        });
        var _filterTypes : number[] = e.target.checked ? filterTypes.concat(e.target.id) : filterTypes.filter(a => a !== e.target.id);
        setFilterTypes(_filterTypes);
        var newFilter: FilterDto | undefined = {
            isAllDocs: props.filter.isAllDocs,
            isMyDocs: props.filter.isMyDocs,
            isAdmin: props.filter.isAdmin,
            recordTypeId: _filterTypes,
            searchTerm: props.filter.searchTerm
        };
        props.onFilterUpdate(newFilter);
    }

    const onRadioChange = async (e: any) => {
        var newFilter: FilterDto | undefined = {
            isAllDocs: e.target.id === '0',
            isMyDocs: e.target.id === '1',
            isAdmin: e.target.id === '2',
            recordTypeId: props.filter.recordTypeId,
            searchTerm: props.filter.searchTerm
        };
        props.onFilterUpdate(newFilter);
        setRadioSelected(e.target.value);
    }

    useEffect(() => {
        if (props.filter.isAllDocs === true) setRadioSelected("All Documents");
        if (props.filter.isMyDocs === true) setRadioSelected("My Documents");
        if (props.filter.isAdmin === true) setRadioSelected("Admin View");
        if (props.filter.recordTypeId?.length === 0) setFilterBy(initial);
    }, [props.filter])

    return (<>
        <Box>
            <Box sx={{ padding: '0 0 10px 0' }}>
                <SearchBar onSearch={onSearchUpdate} />
            </Box>
            <Paper variant="outlined" style={{ flex: 1, padding: '0px 0px 20px 20px' }}>
                <br />
                <Typography
                    gutterBottom
                    component="h3"
                    variant="subtitle1"
                    color="inherit"
                    align="left"
                    noWrap
                    sx={{ color: '#808080' }}
                >
                    Show...
                </Typography>
                <Box>
                    <RadioGroup id="filterRadio" defaultValue="All Documents" value={radioSelected} onChange={(e) => onRadioChange(e)}>
                        <FormControlLabel id="radioAllDocuments" value="All Documents" label="All Documents" control={<Radio id='0' size="small" />} />
                        <FormControlLabel id="radioMyDocuments" value="My Documents" label="My Documents" control={<Radio id='1' size="small" />} />
                        {user?.roleId === 3 ? <FormControlLabel id="radioAdmin" value="Admin View" label="Admin View" control={<Radio id='2' size="small" />} /> : <></>}
                    </RadioGroup>
                </Box>
                <br />
                <Typography
                    gutterBottom
                    component="h3"
                    variant="subtitle1"
                    color="inherit"
                    align="left"
                    noWrap
                    sx={{ color: '#808080' }}
                >
                    Filter by...
                </Typography>
                <Box>
                    <FormGroup>
                        <FormControlLabel label="Form" control={<Checkbox checked={filterBy.form} id='11' name="form" size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        <FormControlLabel label="Work Instruction" control={<Checkbox checked={filterBy.workinstruction} name="workinstruction" id='14' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        <FormControlLabel label="Work Procedure" control={<Checkbox checked={filterBy.workprocedure} name="workprocedure" id='10' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        <FormControlLabel label="Quality Plan" control={<Checkbox checked={filterBy.qualityplan} name="qualityplan" id='6' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        <FormControlLabel label="Quality Manual" control={<Checkbox checked={filterBy.qualitymanual} name="qualitymanual" id='5' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        <FormControlLabel label="Non-QC" control={<Checkbox checked={filterBy.nonqc} name="nonqc" id='16' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        {/*<FormControlLabel label="Unstructured" control={<Checkbox checked={filterBy.unstructured} name="unstructured" id='15' size="small" onChange={(e) => onFilterByUpdate(e)} />} />*/}
                        {/*<FormControlLabel label="Unstructured Work Instruction" control={<Checkbox checked={filterBy.unworkinstruction} name="unworkinstruction" id='13' size="small" onChange={(e) => onFilterByUpdate(e)} />} />*/}
                        {/*<FormControlLabel label="Unstructured Work Procedure" control={<Checkbox checked={filterBy.unworkprocedure} name="unworkprocedure" id='12' size="small" onChange={(e) => onFilterByUpdate(e)} />} />*/}
                        <FormControlLabel label="Template" control={<Checkbox checked={filterBy.template} name="template" id='101' size="small" onChange={(e) => onFilterByUpdate(e)} />} />
                        {user?.roleId === 3 ? <FormControlLabel label="Obsolete" control={<Checkbox checked={filterBy.obsolete} name="obsolete" id='102' size="small" onChange={(e) => onFilterByUpdate(e)} />} /> : <></>}
                        {user?.roleId === 3 ? <FormControlLabel label="Expired" control={<Checkbox checked={filterBy.expired} name="expired" id='103' size="small" onChange={(e) => onFilterByUpdate(e)} />} /> : <></>}
                        {/*<FormControlLabel label="Non QC Document" control={<Checkbox checked={filterBy.nonqc} id='15' size="small" onChange={(e) => onFilterByUpdate(e)} />} />*/}
                    </FormGroup>
                </Box>
            </Paper>
        </Box>
    </>)
}

