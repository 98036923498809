import React, { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { setLoading, setTitle, setError } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { Strings } from '../../Strings'
import { API } from '../../Utils';
import { useQuery } from '../../Hooks';
import { RecordDto, RecordVersion } from '../../generated/ClientApi';
import { Button, Stack, Box, Typography, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { EditModal } from '../../Popups/edit/Edit';
import { setUsers } from '../../redux/appSlice';
import { BasicWarning } from '../../Popups/dialogs/BasicWarning';

export const Review = (): JSX.Element => {
    const dispatch = useStoreDispatch();
    const api = API.getClientApi();
    const query = useQuery();
    const user = useStoreSelector(state => state.app.user);
    const [record, setRecord] = useState<RecordDto>();
    const [latestMetadata, setLatestMetadata] = useState<string>("");
    const [publishedMetadata, setPublishedMetadata] = useState<string>("");
    const [latestVersion, setLatestVersion] = useState<RecordVersion>();
    const [comment, setComment] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [statusPageText, setStatusPageText] = useState<string>("This record is not available for review.");
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [isReviewer, setIsReviewer] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const [popupIndex, setPopupIndex] = useState<number>(0);

    const loadPage = useCallback(async () => {
        await query(async () => {
            const [isReviewer, latestRecord] = await Promise.all([
                api.recordClient.isDocumentApprover(params.id),
                api.recordClient.getRecord(params.id)
            ]);
            var html = await api.commonClient.generateTitlePageHTML(latestRecord.latestVersionId, true, false);
            if (latestRecord.publishedVersionId != null) {
                var publishedVersion = await api.commonClient.generateTitlePageHTML(latestRecord.publishedVersionId, false, false);
                setPublishedMetadata(publishedVersion);
            }
            setIsReviewer(isReviewer);
            setLatestVersion(latestRecord.latestVersion);
            setRecord(latestRecord);
            setLatestMetadata(html);
        });
    }, [api.commonClient, api.recordClient, params.id, query]);

    const getAllUsers = useCallback(async () => {
        var allUsers = await api.accountClient.getUsers();
        dispatch(setUsers(allUsers));
    }, [api.accountClient, dispatch]);

    useEffect(() => {
        dispatch(setTitle(Strings.pageTitles.review));
        loadPage();
        getAllUsers();
    }, [dispatch, loadPage]);

    const editDocument = async () => {
        if (user?.userId !== latestVersion?.originatorId) {
            setPopupIndex(1);
        } else {
            setStatusPageText("Review edits are reserved for the approver of this document. You are the originator of this document version and can not approve or make changes from this page. Please make any edits from the My Documents or Admin View.");
            setPopupIndex(2);
        }

    }

    const approve = async (e: any) => {
        await query(async () => {
            if (latestVersion?.versionStatusId === 8) {
                await api.recordClient.submitForApproval(record?.latestVersionId)
            }
            else {
                await api.recordClient.approveVersion(record?.latestVersionId, comment)
            }
            setStatusPageText("Thank you for reviewing.");
            setSubmitted(true);
            navigate("/");
        });
    }

    const reject = async (e: any) => {
        if (comment === "") {
            setErrorText(Strings.errors.commentReqired)
        } else {
            await query(async () => {
                await api.recordClient.rejectVersion(record?.latestVersionId, comment)
                setStatusPageText("Thank you for reviewing.");
                setSubmitted(true);
                navigate("/");
            });
        }
    }

    const highlightChange = (text: string) => {
        return <span style={{ backgroundColor: '#FFFF8F', border: '1px solid black', padding: '2px' }}>{text}</span>
    }

    const view = async (id: string | undefined) => {
        dispatch(setLoading(true));
        try {
            await api.documentClient.downloadFile(id)
                .then((file) => {
                    const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute(
                        'download',
                        file.name != null ? file.name : file.documentId
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(setLoading(false));
        }
    }

    return (<div style={{/* height: 750,*/ width: '100%' }}>
        {(latestVersion?.versionStatusId === 2 && isReviewer && !submitted) || (latestVersion?.versionStatusId === 2 && user?.roleId === 3) || (latestVersion?.versionStatusId === 8 && user?.roleId === 3) ?
            <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
                {user?.userId === latestVersion?.originatorId ?
                    <Box><Typography id="modal-modal-title" variant="subtitle1" sx={{ textAlign: 'center', color: 'red' }}>
                        Read Only. Originator cannot approve own document.
                    </Typography></Box>
                    : null
                }
                <Stack direction="row" spacing={1} >
                    {publishedMetadata !== "" ?
                        <Stack direction="column" spacing={1}>
                            <Typography id="modal-modal-title" variant="body1" sx={{ textAlign: 'center', color: 'green' }}>
                                {'Published ' + record?.recordTypeName}
                            </Typography>
                            <Box sx={{ height: '500px', width: '750px', border: '1px solid #000', borderRadius: '5px', overflowY: 'auto' }}>
                                <Stack direction="column" spacing={1}>
                                    <Box sx={{ p: '10px', paddingBottom: '0px' }}>
                                        <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Box>
                                                <Typography id="modal-modal-title" variant="body2">
                                                    <b>Name</b>: {record?.name} <br />
                                                    <b>Document Id:</b> {record?.recordPublicId} <br />
                                                    <b>Project #:</b> {record?.projectNumber} <br />
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Button color="info" variant='contained' size='small' onClick={() => view(record?.publishedVersionId)}>{Strings.button.view}</Button>
                                            </Box>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ padding: '10px', paddingTop: '0px' }}>{parse(publishedMetadata)}</Box>
                                </Stack>
                            </Box>
                        </Stack>
                        : <></>}
                    <Stack direction="column" spacing={1}>
                        <Typography id="modal-modal-title" variant="body1" sx={{ textAlign: 'center', color: 'red' }}>
                            {'Submitted ' + record?.recordTypeName}
                        </Typography>
                        <Box sx={{ height: '500px', width: '750px', border: '1px solid #000', borderRadius: '5px', overflow: 'auto' }}>
                            <Stack direction="column" spacing={1}>
                                <Box sx={{ p: '10px', paddingBottom: '0px' }}>
                                    <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography id="modal-modal-title" variant="body2">
                                                <b>Name:</b> {latestVersion?.name != null ? highlightChange(latestVersion?.name) : record?.name} <br />
                                                <b>Document Id:</b> {latestVersion?.recordPublicId != null ? highlightChange(latestVersion?.recordPublicId) : record?.recordPublicId} <br />
                                                <b>Project #:</b> {latestVersion?.projectNumber != null ? highlightChange(latestVersion?.projectNumber) : record?.projectNumber} <br />
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {record == null ? <></> : <Button disabled={user?.userId === latestVersion?.originatorId} color="info" variant='contained' size='small' onClick={() => editDocument()}>{Strings.button.edit}</Button>}&nbsp;
                                            <Button color="info" variant='contained' size='small' onClick={() => view(record?.latestVersionId)}>{Strings.button.view}</Button>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box sx={{ padding: '10px', paddingTop: '0px' }}>{parse(latestMetadata)}</Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
                <br />
                <Stack direction="column" spacing={1} >
                    <Typography id="modal-modal-title" variant="body1" sx={{ textAlign: 'center' }}>
                        Leave a Comment
                    </Typography>
                    <TextField disabled={user?.userId === latestVersion?.originatorId} sx={{ width: '400px' }} id="standard-basic" label="Comment" variant="outlined" multiline minRows={3} maxRows={3} onChange={(e) => setComment(e.target.value)} />
                </Stack>
                <Typography id="modal-modal-title" variant="caption" sx={{ color: 'red', p: 0, m: 0 }}>
                    {errorText}
                </Typography>
                <Stack direction="row" spacing={1} style={{ justifyContent: 'center' }}>
                    <Button disabled={user?.userId === latestVersion?.originatorId} color="success" variant='contained' size='medium' onClick={approve}>{Strings.button.approve}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button disabled={user?.userId === latestVersion?.originatorId} color="error" variant='contained' size='medium' onClick={reject}>{Strings.button.reject}</Button>
                </Stack>
                <br />
            </Stack>
            : <>{statusPageText}</>}
        {popupIndex === 1 && record != null ?
            <EditModal isOpen={popupIndex === 1} dismiss={() => { setPopupIndex(0); loadPage(); }} record={record} isReviewEdit={true} />
            : null}
        {popupIndex === 2 ?
            <BasicWarning isOpen={popupIndex === 2} dismiss={() => { setPopupIndex(0); }} message={statusPageText} />
            : null}
    </div>);
}