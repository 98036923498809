import React, { useCallback } from 'react';
import { API } from '../../Utils';
import { StakeholdersSection, UserDto } from '../../generated/ClientApi';
import { MultiUserAutocomplete } from '../../components/user/MultiUserAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, IconButton, Box } from '@mui/material';
import { useEffect } from 'react';

export const StakeholderSection = (props: { stakeholderType: StakeholdersSection, users: UserDto[], onUserChange: (type: StakeholdersSection, users: UserDto[]) => void, onSectionRemoved: (type: StakeholdersSection) => void }) => {
    const api = API.getClientApi();

    const onChange = useCallback((e: any, value: UserDto[]) => {
        if (e != null) e.preventDefault();
        props.onUserChange(props.stakeholderType, value);
    }, [props]);
    const onSectionRemoved = () => {
        props.onSectionRemoved(props.stakeholderType);
    }

    const loadSection = useCallback(async () => {
        if (props.users.length === 0) {
            var _users = await api.stakeholderClient.getStakeholdersTemplate(props.stakeholderType.stakeholderSectionId)
            onChange(null, _users);
        } 
    }, [api.stakeholderClient, onChange, props.stakeholderType.stakeholderSectionId, props.users.length]);

    useEffect(() => {
        loadSection();
    }, []);

    return (<Box sx={{ width: '390px' }}>
        <Stack direction="column">
            <Stack direction="row" sx={{ marginTop: '10px' }}>
                {props.stakeholderType.name !== undefined ?
                    <MultiUserAutocomplete users={props.users} selectUser={onChange} label={props.stakeholderType.name} />
                    : null}
                <IconButton sx={{ margin: '0' }} onClick={() => onSectionRemoved()}><CloseIcon fontSize="small" /></IconButton>
            </Stack>
        </Stack>
    </Box>)
}