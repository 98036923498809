import { useEffect, useState } from 'react';
import { useStoreDispatch } from '../../redux/store';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { useQuery } from '../../Hooks';
import { RecordDto, RecordVersionDto } from '../../generated/ClientApi';
import { Box, Typography, Modal, Stack, Button, Tooltip, IconButton } from '@mui/material';
import { setLoading, setError } from '../../redux/layoutSlice';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Download } from '@mui/icons-material';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '60%',
    //height: '400px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    //overflow: 'scroll'
};

export const HistoryModal = (props: { isOpen: boolean, dismiss: () => void, record: RecordDto }) => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const api = API.getClientApi();
    const [versions, setVersions] = useState<RecordVersionDto[] | null>(null);

    const loadPage = async () => {
        await query(async () => {
            var v = await api.recordClient.getRecordVersions(props.record.recordId);
            setVersions(v);
        });
    };

    const download = async (versionId: string) => {
        dispatch(setLoading(true));
        try {
            await api.documentClient.downloadFile(versionId)
                .then((file) => {
                    const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                    const link = document.createElement("a");
                    link.href = blobUrl;
                    link.setAttribute(
                        'download',
                        file.name != null ? file.name : file.documentId
                    );
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(setLoading(false));
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    const closeUpload = () => {
        props.dismiss();
    };

    const actionColumn = (e: any) => {
        return (
            <span style={{ textAlign: 'right' }}>
                {e.row.documentId == null ? null : <Tooltip title="Download"><IconButton onClick={() => download(e.row.versionId)} ><Download fontSize="small" /></IconButton></Tooltip>}
            </span>
        );
    };

    const columns: GridColDef[] = [
        //{ field: 'versionId', headerName: 'Version Id', minWidth: 50, flex: 1 },
        { field: 'changeSectionNumber', headerName: 'Section Number', valueFormatter: (a) =>  props.record.recordTypeId === 11 && a.value !== "" ? a.value.split(',')[0] : a.value, minWidth: 125, flex: 1 },
        { field: 'changeSectionName', headerName: 'Section Name', valueFormatter: (a) => props.record.recordTypeId === 11 && a.value !== "" ? a.value.split(',')[0] : a.value, minWidth: 125, flex: 1 },
        { field: 'summaryChanges', headerName: 'Summary', minWidth: 150, flex: 1},
        { field: 'originator', headerName: 'Originator', minWidth: 120, flex: 1 },
        { field: 'approvedBy', headerName: 'Approved By', minWidth: 120, flex: 1 },
        { field: 'versionStatus', headerName: 'Status', minWidth: 90, flex: 1 },
        { field: 'created', type: 'dateTime', headerName: 'Issue Date', valueFormatter: (a) => new Date(a?.value).toLocaleDateString(), minWidth: 100, flex: 1 },
        { field: 'action', headerName: '', minWidth: 50, maxWidth: 50, sortable: false, renderCell: (e) => actionColumn(e) }
    ];

    return (<>
        <Modal
            open={props.isOpen}
            onClose={closeUpload}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2" >
                    {props.record?.name} - Versions
                </Typography>
                <Stack direction="column" spacing={1}>
                    <div style={{ height: '400px', minWidth: 0, overflow: 'auto' }}>
                        <DataGridPro
                            autoHeight
                        getRowId={(r) => r.versionId}
                        rows={versions == null ? [] : versions}
                        columns={columns}
                        rowHeight={25}
                        loading={versions == null}
                        />
                    </div>
                    <div>
                        <Stack direction="row" spacing={1} style={{ display: 'flex', float:'right'}}>
                            <span>
                                <Button color="error" variant='contained' size='small' onClick={closeUpload}>{Strings.button.cancel}</Button>
                            </span>
                        </Stack>
                    </div>
                </Stack>
            </Box>
        </Modal>
    </>)
}

