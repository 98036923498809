import React from 'react';
import { useStoreSelector } from '../../redux/store';
import { CircularProgress } from '@mui/material';

export const GlobalLoading = () => {
    const loading = useStoreSelector(state => state.layout.loading);
    const loadingStyle = {
        //position: 'fixed' as 'fixed',//'absolute' as 'absolute',
        position: 'absolute' as 'absolute',//'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999
    }
    return (<>
        {loading && <div style={loadingStyle}><CircularProgress /></div>}
    </>
    );
}

//{loading && <div style={loadingStyle}><CircularProgress /></div>}
//{<Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} ><CircularProgress /></Backdrop>}