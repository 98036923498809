import React from 'react';
import { MenuButton } from './MenuButton';
import { Box, Grid, Stack } from '@mui/material';
import { NavTitle } from './NavTitle'
import { Strings } from '../../Strings';

export const NavMenu = () => {
    const documentMenu = [{ name: "Documents", link: "https://mcdean.com" }, { name: "Templates", link: "https://mcdean.com" }];
    const guideMenu = [{ name: "Guides", link: "https://mcdean.com" }];
    const feedback = [{ name: "Report a bug", link: Strings.links.devOpsBug }, { name: "Suggest feature", link: Strings.links.devOpsFeature }, { name: "Change Log", link: Strings.links.devOpsChangeLog }];

    return (<>
        <Box sx={{ height: 60, flex: 1, alignItems: 'center' }}>
            <Grid container columns={16}>
                <Grid item xs={4}>
                    <a href={window.location.origin}><img src="https://cdn.mcdean.com/images/logo.png" height='36.5px' alt=""/></a>
                </Grid>
                <Grid item xs={8}>
                    <NavTitle />
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <MenuButton menuName="Feedback" menuItems={feedback} />
                        <MenuButton menuName="Documents" menuItems={documentMenu} />
                        <MenuButton menuName="Guides" menuItems={guideMenu} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    </>)
}

