import { Cancel, Download } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ToastTypes } from '../../Enums';
import { useQuery } from '../../Hooks';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { Stakeholders } from '../../components/stakeholder/Stakeholders';
import { UserAutocomplete } from '../../components/user/UserAutocomplete';
import { EditRecordDto, RecordDto, RecordVersion, StakeholdersDto, UserDto, Document, RecordVersionChangesDto } from '../../generated/ClientApi';
import { setToast } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { Summary as SummaryModal } from './Summary';
import { DeleteDraft } from '../dialogs/DeleteDraft';


export const EditModal = (props: { isOpen: boolean, dismiss: () => void, record: RecordDto, isReviewEdit: boolean | null }) => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const api = API.getClientApi();
    const user = useStoreSelector(state => state.app.user);
    const [stakeholders, setStakeholders] = useState<StakeholdersDto[]>();
    const [approvers, setApprovers] = useState<UserDto[]>();
    const [originator, setOriginator] = useState<UserDto | null>(null);
    const [recordVersion, setRecordVersion] = useState<RecordVersion>();
    const [record, setRecord] = useState<RecordDto>();
    const [startedEditDoc, setStartedEditDoc] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>();
    const unstructuredDocIds = [12, 13, 15];
    const [obsolete, setObsolete] = useState(false);
    const [popupIndex, setPopupIndex] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");
    const [inProgressDoc, setInProgressDoc] = useState<Document>();

    const loadPage = useCallback(async () => {
        await query(async () => {
            const [record, approvers, sh, originator] = await Promise.all([
                api.recordClient.getRecord(props.record.recordId),
                api.recordClient.getApprovers(props.record.latestVersionId),
                api.stakeholderClient.getStakeholders(props.record.recordId),
                api.accountClient.getUserById(props.record.originator?.userId)
            ]);
            if ((record.recordStatusId === 1 || record.recordStatusId === 2 || record.recordStatusId === 5) && record.document?.documentId !== record.latestVersion?.documentId) {
                setInProgressDoc(await api.documentClient.getDocument(record.latestVersion?.documentId));
            }
            if (props.record.latestVersion?.name != null) {
                record.name = props.record.latestVersion?.name
            }
            if (props.record.latestVersion?.recordPublicId != null) {
                record.recordPublicId = props.record.latestVersion?.recordPublicId
            }
            if (props.record.latestVersion?.projectNumber != null) {
                record.projectNumber = props.record.latestVersion?.projectNumber
            }
            setRecord(record);
            setRecordVersion(record.latestVersion);
            setApprovers(approvers);
            setStakeholders(sh);
            setOriginator(user!.roleId !== 3 ? user! : originator);
        });
    }, [api.recordClient, api.stakeholderClient, props.record, query]);

    const onStakeholderUpdate = (_stakeholders: StakeholdersDto[]) => {
        setStakeholders(_stakeholders);
    }

    const onApproverChange = (_approvers: UserDto) => {
        var a = [];
        a.push(_approvers);
        setApprovers(a);
    }

    const onOriginatorChange = (originator: UserDto) => {
        setOriginator(originator);
    }

    useEffect(() => {
        loadPage();
        if (props.record?.recordStatusId === 6) setObsolete(true);
    }, []);

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        //width: '80%',
        //height: '80%',
        borderRadius: '10px',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 2,
        overflow: 'hidden'
    };

    const saveEdit = async () => {
        var editVersion: EditRecordDto = {
            recordId: record!.recordId,
            recordPublicId: record?.recordPublicId,
            name: record?.name,
            projectNumber: record?.projectNumber,
            stakeholders: stakeholders,
            recordVersion: recordVersion,
            originatorId: originator!.userId,
            isObsolete: obsolete,
            //record: record,
            approvers: approvers,
            saveAsDraft: true,
            saveAsInProgress: false,
            recordVersionChanges: undefined
        }
        await query(async () => {
            if (file != null) {
                var document = unstructuredDocIds.includes(record!.recordTypeId) ? null : { data: file!, fileName: file!.name };
                var documentId = await api.documentClient.uploadDocument(document, record!.recordTypeId);
                editVersion.attachmentDocId = documentId;
            }
            await api.recordClient.addEditVersion(editVersion!);
            props.dismiss();
        });
    };

    const discardSave = async () => {
        await query(async () => {
            await api.recordClient.discardEdit(record!.recordId);
            props.dismiss();
        });
    }

    const deleteDocument = async () => {
        setPopupIndex(2);
    }

    const cancelEdit = async () => {
        if (startedEditDoc && record?.recordStatusId === 3) {
            await query(async () => {
                await api.recordClient.discardEdit(record!.recordId);
            });
        } else if (startedEditDoc && record?.recordStatusId === 2) {
            await query(async () => {
                await api.recordClient.discardPendingEdit(record!.recordId);
            });
        }
        props.dismiss();
    };

    const download = async () => {
        if (record?.latestVersion?.versionId != null) {
            await query(async () => {
                await api.documentClient.downloadFile(record?.latestVersion?.versionId)
                    .then((file) => {
                        const blobUrl = "data:" + file.contentType + ";base64," + file.fileData;
                        const link = document.createElement("a");
                        link.href = blobUrl;
                        link.setAttribute(
                            'download',
                            file.name != null ? file.name : file.documentId
                        );
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
            });
        }
    }

    const changesModal = async () => {
        var errorList = "";
        errorList += record?.name === "" || record?.name === undefined || record === undefined ? "Name; " : "";
        errorList += record?.recordPublicId === "" || record?.recordPublicId === undefined || record === undefined ? "Document Id; " : "";
        errorList += stakeholders?.length === 0 || stakeholders === undefined ? "Stakeholders; " : "";
        errorList += approvers === null || approvers === undefined ? "Approver; " : "";
        if (errorList !== "") {
            setErrorText("Missing required field: " + errorList);
            setError(true);
        }
        else if ((approvers !== null && originator !== null && approvers![0].userId === originator?.userId)
            || (approvers !== null && (originator === undefined || originator === null) && approvers![0].userId === user?.userId)) {
            setErrorText(user?.roleId !== 3 ? "You cannot approve your own document":Strings.errors.approverOriginatorMatch);
            setError(true);
        }
        else if (props.record.recordPublicId !== record?.recordPublicId && await api.recordClient.hasDuplicatePublicId(record?.recordPublicId)) {
            setErrorText(Strings.errors.duplicateDocId);
            setError(true);
        }
        else {
            setError(false);
            if (record?.publishedVersionId != null) {
                setPopupIndex(1);
            }
            else {
                submit([]);
            }
        }
    }

    const submit = async (changes: RecordVersionChangesDto[] | undefined) => {
        var editVersion: EditRecordDto = {
            recordId: record!.recordId,
            recordPublicId: record?.recordPublicId,
            name: record?.name,
            projectNumber: record?.projectNumber,
            isObsolete: obsolete,
            originatorId: originator!.userId,
            stakeholders: stakeholders,
            recordVersion: recordVersion,
            approvers: approvers,
            saveAsDraft: false,
            saveAsInProgress: props.record.recordStatusId === 2,
            recordVersionChanges: changes
        }
        editVersion.recordVersion!.approvers = [];
        editVersion.recordVersion!.stakeholders = [];
        await query(async () => {
            if (file != null) {
                var document = unstructuredDocIds.includes(record!.recordTypeId) ? null : { data: file!, fileName: file!.name };
                var documentId = await api.documentClient.uploadDocument(document, record!.recordTypeId);
                editVersion.attachmentDocId = documentId;
            }
            await api.recordClient.addEditVersion(editVersion);
            props.dismiss();
        });
    }

    const editFile = async () => {
        await query(async () => {
            if (!startedEditDoc) {
                var res = await api.documentClient.editFile(props.record.recordId);
                if (res) {
                    setStartedEditDoc(true);
                    window.open(res, '_blank');
                } else {
                    dispatch(setToast({ type: ToastTypes.ERROR, text: Strings.toasts.submitFailed }))
                }
            } else {
                var temporaryVersion = await api.recordClient.getTemporaryEditVersion(props.record.recordId);
                var editLink = await api.documentClient.getEditLink(temporaryVersion.documentId);
                window.open(editLink, '_blank');
            }
        });
    }

    const fileChanged = async (files: FileList | null) => {
        if (files) {
            setFile(files[0]);
        }
    }

    const clearFile = () => {
        setFile(null);
    }

    return (<>
        <Modal
            open={props.isOpen}
            //onClose={closeUpload}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ padding: '0 0 20px 20px' }}>
                    Edit {props.record.name}
                </Typography>
                <Stack direction="column" spacing={1} style={{ height: '100%' }}>
                    <Stack direction="row" spacing={1} >
                        <FormControl fullWidth>
                            <Box component='form' sx={{ padding: '20px', height: '550px', width: '450px'/*, overflowY: 'scroll'*/ }} noValidate autoComplete='off'>
                                {recordVersion != null ?
                                    <>
                                        <TextField
                                            id="standard-basic"
                                            fullWidth
                                            disabled={user?.roleId! !== 3}
                                            label={Strings.labels.name}
                                            variant="standard"
                                            value={record?.name == null ? "" : record?.name}
                                            margin="normal"
                                            onChange={(e) => { setRecord(record => ({ ...record!, name: e.target.value })) }}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            fullWidth
                                            disabled={record?.publishedVersionId != null}
                                            label={Strings.labels.documentId}
                                            variant="standard"
                                            value={record?.recordPublicId == null ? "" : record?.recordPublicId}
                                            margin="normal"
                                            onChange={(e) => { setRecord(record => ({ ...record!, recordPublicId: e.target.value })) }}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            fullWidth
                                            disabled={user?.roleId! !== 3}
                                            label={Strings.labels.projectNumber}
                                            variant="standard"
                                            value={record?.projectNumber == null ? "" : record?.projectNumber}
                                            margin="normal"
                                            onChange={(e) => { setRecord(record => ({ ...record!, projectNumber: e.target.value })) }}
                                        />
                                    </>
                                    : <></>}
                                {approvers != null ?
                                    <UserAutocomplete initialUser={approvers[0]} selectUser={onApproverChange} label={Strings.labels.approver} />
                                    : <></>}
                                <br />
                                {user?.roleId === 3 ?
                                    <Stack direction="column" spacing={1}>
                                        {originator !== null ?
                                            <UserAutocomplete initialUser={originator} selectUser={onOriginatorChange} label={Strings.labels.originator} />
                                            : <></>
                                        }
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={obsolete}
                                                    onChange={(e) => setObsolete(e.target.checked)}
                                                />
                                            }
                                            label="Obsolete"
                                        />
                                    </Stack>
                                    : <></>
                                }
                            </Box>
                        </FormControl>
                        {recordVersion != null ?
                            <Box component='form' sx={{ height: '550px', width: '420px' }} noValidate autoComplete='off'>
                                {stakeholders !== null ? <Stakeholders onUpdate={onStakeholderUpdate} currentStakeholders={stakeholders} versionId={recordVersion.versionId!} /> : <></>}
                            </Box>
                            : <></>
                        }
                    </Stack>
                    {error ?
                        <span style={{ textAlign: 'center' }}>
                            <Typography id="modal-modal-title" variant="caption" sx={{ color: 'red' }}>
                                {errorText}
                            </Typography>
                        </span>
                        : <></>
                    }
                    <Box>
                        <Stack direction="row" spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                {record?.document?.ext !== ".pdf" && inProgressDoc?.ext !== ".pdf" ?
                                    <>
                                        {startedEditDoc ?
                                            <></>
                                            : (<Tooltip title="Overwrite With New File" placement="top"><Button variant="outlined" color="primary" size='small' component="label">{Strings.button.attachment}<input type="file" hidden multiple={false} onChange={(e) => fileChanged(e.target.files)} onClick={(e) => e.currentTarget.value = ""} /></Button></Tooltip>)
                                        }
                                        &nbsp;&nbsp;
                                        {file != null ?
                                            <Typography variant="caption">
                                                <span>{file?.name}<IconButton sx={{ padding: '0px' }} onClick={() => clearFile()} ><Cancel color="error" fontSize="small" /></IconButton></span>
                                            </Typography>
                                            : (<Tooltip title="Edit File With Online Editor" placement="top"><Button color="primary" variant='outlined' size='small' onClick={editFile}>{Strings.button.editFile}</Button></Tooltip>)
                                        }
                                    </>
                                    :
                                    <>
                                        <><Tooltip title="Overwrite With New File" placement="top"><Button variant="outlined" color="primary" size='small' component="label">{Strings.button.attachment}<input type="file" hidden multiple={false} onChange={(e) => fileChanged(e.target.files)} onClick={(e) => e.currentTarget.value = ""} /></Button></Tooltip>&nbsp;&nbsp;</>
                                        {file != null ?
                                            <Typography variant="caption">
                                                <span>{file?.name}<IconButton sx={{ padding: '0px' }} onClick={() => clearFile()} ><Cancel color="error" fontSize="small" /></IconButton></span>
                                            </Typography>
                                            :
                                            <></>
                                        }
                                    </>
                                }
                                <>{inProgressDoc && !startedEditDoc ?
                                    <>
                                        &nbsp;&nbsp;
                                        <Typography variant="caption">
                                            <span>Draft document<IconButton sx={{ padding: '0px' }} onClick={() => download()} ><Download fontSize="small" /></IconButton></span>
                                        </Typography></>
                                    : <></>
                                }</>
                            </span>
                            <span>
                                {record?.recordStatusId === 1 ?
                                    <>
                                        {record?.publishedVersionId == null ?
                                            <><Button color="error" variant='outlined' size='small' onClick={deleteDocument}>{Strings.button.deleteDocument}</Button>&nbsp;</>
                                            : <><Button color="error" variant='outlined' size='small' onClick={discardSave}>{Strings.button.discardSave}</Button>&nbsp;</>
                                        }
                                    </>
                                    : <></>
                                }
                                {record?.recordStatusId !== 2 ?
                                    <>
                                        <Button color="info" variant='outlined' size='small' onClick={saveEdit}>{Strings.button.draft}</Button>&nbsp;
                                    </>
                                    : <></>
                                }
                                <>
                                    <Button color="info" variant='contained' size='small' onClick={changesModal}>{record?.recordStatusId === 2 ? Strings.button.save : Strings.button.submitForApproval}</Button>&nbsp;
                                </>
                                <><Button color="error" variant='contained' size='small' onClick={cancelEdit}>{Strings.button.cancel}</Button>&nbsp;</>
                            </span>
                        </Stack>
                    </Box>
                </Stack>
                {popupIndex === 1 ?
                    <SummaryModal isOpen={popupIndex === 1} dismiss={() => { setPopupIndex(0); }} onUpdate={submit} recordVersion={record?.latestVersion!} recordType={record!.recordTypeId} isInitialRecord={record!.publishedVersionId == null} isObsolete={obsolete} />
                    : <></>}
                {popupIndex === 2 ?
                    <DeleteDraft isOpen={popupIndex === 2} dismiss={() => { setPopupIndex(0); props.dismiss(); }} record={record!} />
                    : <></>}

            </Box>
        </Modal>
    </>)
}

