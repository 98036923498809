import React from 'react';
import { RouterConfigEntry } from './models/interfaces';
import { Home } from './pages/Home/Home';
import { Admin } from './pages/Admin/Admin';
import { Review } from './pages/Review/Review';
import { View } from './pages/View/View';

export const userIsAllowed = (roles: number[], userRoles: number): boolean => {
    if (roles.length === 0) return true;
    return roles.findIndex(a => (userRoles & a) > 0) >= 0;
}

export const RouterConfig: RouterConfigEntry[] = [
    {
        route: '/',
        render: <Home />,
        roles: [],
        inMenu: true,
        name: "Home",
        exact: true
    },
    {
        route: '/Admin',
        render: <Admin />,
        roles: [],
        inMenu: true,
        name: "Admin",
        exact: false
    },
    {
        route: '/Review/:id',
        render: <Review />,
        roles: [],
        inMenu: true,
        name: "Submission Review",
        exact: false
    },
    {
        route: '/View/:id',
        render: <View />,
        roles: [],
        inMenu: true,
        name: "View",
        exact: false
    }
];