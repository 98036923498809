import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '../../Hooks';
import { NewModal } from '../../Popups/new/NewRecord';
import { Strings } from '../../Strings';
import { API } from '../../Utils';
import { DocumentGridFilter } from '../../components/filters/GridFilter';
import { FilterDto, RecordGridDto, RecordType } from '../../generated/ClientApi';
import { setUsers } from '../../redux/appSlice';
import { setError, setTitle } from '../../redux/layoutSlice';
import { useStoreDispatch, useStoreSelector } from '../../redux/store';
import { DocumentGrid } from './DocumentGrid';
import { NewButton } from './NewButton';

export const Home = (): JSX.Element => {
    const dispatch = useStoreDispatch();
    const query = useQuery();
    const user = useStoreSelector(state => state.app.user);
    const api = API.getClientApi();
    const [records, setRecords] = useState<RecordGridDto[]>([]);
    const [recordType, setRecordType] = useState<RecordType>({ recordTypeId: 0, hidden: false });
    const [filter, setFilter] = useState<FilterDto>({ isAllDocs: true, isMyDocs: false, isAdmin: false, recordTypeId: [], searchTerm: "" });
    const [requiredReviewsCount, setRequiredReviewsCount] = useState<number>(0);
    const [gridLoading, setGridLoading] = useState<boolean>(false);
    const [popupIndex, setPopupIndex] = useState<number>(0);

    const onRecordTypeSelected = (recordType: RecordType) => {
        setRecordType(recordType);
        setPopupIndex(1);
    };

    const getDocuments = useCallback(async () => {
        try {
            setGridLoading(true);
            if (filter.isMyDocs) {
                await navigateToTab('mydocuments');
            } else if (filter.isAdmin) {
                await navigateToTab('admin');
            } else {
                await navigateToTab('alldocuments');
            }
            checkForRequiredReviews();
        } catch (e) {
            dispatch(setError(e));
        } finally {
            setGridLoading(false);
        }
    }, [api.recordClient, dispatch, filter.isMyDocs, filter.isAdmin]);

    const getAllUsers = useCallback(async () => {
        var allUsers = await api.accountClient.getUsers();
        dispatch(setUsers(allUsers));
    }, [api.accountClient, dispatch]);

    const checkForRequiredReviews = useCallback(async () => {
        var res = await api.recordClient.pendingReviewRequired();
        setRequiredReviewsCount(res);
    }, [api.recordClient]);

    useEffect(() => {
        dispatch(setTitle(Strings.pageTitles.home));

    }, [dispatch]);

    useEffect(() => {
        getDocuments();
        getAllUsers();
    }, [])

    const importxml = async () => {
        await api.commonClient.importDocumentXML();
    };

    const onFilterUpdate = async (filter: FilterDto) => {
        setFilter(filter);
        await query(async () => {
            var res = await api.recordClient.filterRecords(filter);
            setRecords(res);
        });
    };

    const navigateToTab = async (tab: string) => {
        var t = tab.toLowerCase();
        var f = { ...filter };
        f.isMyDocs = t === 'mydocuments';
        f.isAdmin = t === 'admin';
        f.isAllDocs = t === 'alldocuments';
        f.searchTerm = "";
        f.recordTypeId = [];
        await onFilterUpdate(f);
    }

    return (<div style={{ height: 300, width: '100%' }}>
        <Box sx={{ height: 45 }}>
            <Grid container columns={16}>
                <Grid item xs={2.5}>
                </Grid>
                <Grid item xs={3.5}>
                    <Stack direction="row" spacing={2} style={{ float: 'left' }}>
                        <Typography
                            gutterBottom
                            component="h3"
                            variant="h6"
                            color="inherit"
                            align="center"
                            noWrap
                            sx={{ marginTop: '10px', color: '#808080' }}
                        >
                            {filter.isAllDocs ? Strings.headers.allDocuments : filter.isMyDocs ? Strings.headers.myDocuments : Strings.headers.adminView}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={10}>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        {user ? <>
                            {requiredReviewsCount > 0 ? <Button variant="outlined" color="warning" size="small" onClick={() => navigateToTab('mydocuments')}>{requiredReviewsCount} Review{requiredReviewsCount > 1 ? "s" : ""} required</Button> : <></>}
                            {user?.roleId === 3 ? <Button variant="outlined" color="success" size="medium" onClick={() => window.open(`${window.location.origin}/admin`, "_self")}>Admin</Button> : <></>}
                            {user?.email === "Ian.Turnbull@MCDEAN.COM" ? <Button variant="outlined" color="success" size="medium" onClick={() => importxml()}>import xml</Button> : <></>}
                            {/*<Button variant="outlined" color="success" size="medium" onClick={() => createnewsubsite()}>create subsite</Button>*/}
                            {user?.roleId !== 1 ? <NewButton onSelected={onRecordTypeSelected} /> : <></>}
                        </> : <></>}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box sx={{ flex: 1 }}>
            <Grid container columns={16} columnSpacing={1} style={{ height: 750 }}>
                <Grid item xs={2.5}>
                    <DocumentGridFilter onFilterUpdate={onFilterUpdate} filter={filter} />
                </Grid>
                <Grid item xs={13.5}>
                    <DocumentGrid records={records} updateGrid={getDocuments} filter={filter} gridLoading={gridLoading} />
                </Grid>
            </Grid>
        </Box>
        {popupIndex === 1 && user !== undefined ?
            <NewModal isOpen={popupIndex === 1} dismiss={() => { setPopupIndex(0); getDocuments(); }} type={recordType} user={user} />
            : <></>}
    </div>);
}