import React, { useState } from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

export const SearchBar = (props: { onSearch: (searchTerm: string) => void }) => {

    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const onEnter = () => {
        props.onSearch(searchTerm);
    }

    return (
        <>
            <TextField
                fullWidth
                id="search"
                //type="search"
                label="Search"
                size="small"
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={(e) => { if (e.key === 'Enter') onEnter() }}
                //sx={{ width: 300 }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => onEnter()} sx={{padding:'0'} }>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}
