import { Strings } from '../../Strings';
import { Button, Dialog, DialogContent, Stack } from '@mui/material';

export const DocumentHelp = (props: { isOpen: boolean, dismiss: () => void, imgURL: string}) => {

    return (<>
        <Dialog onClose={() => props.dismiss()} open={props.isOpen} maxWidth={false} hideBackdrop={true} sx={{height: '65%',top: '50%',left: '50%',transform: 'translate(-50%, -50%)'} }>
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    <img alt="document-id-img" src={props.imgURL} width='650px' />
                    <img alt="divisions-img" src={Strings.links.divisionsHelp} width='650px' />
                    <Button color="info" variant='contained' size='small' sx={{ width: '120px' }} onClick={() => window.open(Strings.links.controlDocument, '_blank')}>{Strings.button.moreInfo}</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    </>)
}
